import { Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { Space } from "@swan-io/lake/src/components/Space";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useRef } from "react";
import { AddMerchantProfileDocument } from "../graphql/partner";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";

import { useProjectInfo } from "../hooks/useProjectInfo";
import {
  AccountDetailMerchantProfileEditor,
  MerchantProfileEditorRef,
  MerchantProfileEditorState,
} from "./AccountDetailMerchantProfileEditor";

type Props = {
  onPressClose: () => void;
  accountId: string;
};

export const AccountDetailMerchantProfileRequestWizard = ({ onPressClose, accountId }: Props) => {
  const [addMerchantProfile, merchantProfileAddition] = useMutation(AddMerchantProfileDocument);
  const editorRef = useRef<MerchantProfileEditorRef>(null);
  const { projectId, projectEnv } = useProjectInfo();

  const onSubmit = (input: MerchantProfileEditorState) => {
    addMerchantProfile({
      input: {
        accountId,
        ...input,
      },
    })
      .mapOkToResult(data =>
        Option.fromNullable(data.requestMerchantProfile).toResult("No response"),
      )
      .mapOkToResult(filterRejectionsToResult)
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      })
      .tapOk(payload => {
        Router.push("AccountDetailMerchantProfile", {
          accountId,
          projectEnv,
          projectId,
          merchantProfileId: payload.merchantProfile.id,
        });
      });
  };

  const onPressSubmit = () => {
    if (editorRef.current != null) {
      editorRef.current.submit();
    }
  };

  return (
    <>
      <Space height={32} />
      <AccountDetailMerchantProfileEditor ref={editorRef} onSubmit={onSubmit} />
      <Space height={16} />

      <LakeButtonGroup paddingBottom={32}>
        <LakeButton
          mode="secondary"
          onPress={onPressClose}
          ariaLabel={t("common.close")}
          grow={true}
        >
          {t("common.cancel")}
        </LakeButton>

        <LakeButton
          onPress={onPressSubmit}
          color="current"
          loading={merchantProfileAddition.isLoading()}
          grow={true}
        >
          {t("merchantProfile.request.requestProfile")}
        </LakeButton>
      </LakeButtonGroup>
    </>
  );
};
