import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Avatar } from "@swan-io/lake/src/components/Avatar";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { ListRightPanel } from "@swan-io/lake/src/components/ListRightPanel";
import { Space } from "@swan-io/lake/src/components/Space";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useCallback, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import { ChangeMembershipRoleV2Document, ProjectMembershipFragment } from "../../graphql/admin";
import { useProjectInfo } from "../../hooks/useProjectInfo";
import { t } from "../../utils/i18n";
import { Role } from "../../utils/permissions";
import { Router } from "../../utils/routes";
import { ErrorView } from "../ErrorView";
import { AddAdminConfirmationModal } from "./AddAdminConfirmationModal";
import { DeleteMemberConfirmationModal } from "./DeleteMemberConfirmationModal";
import {
  MembershipBulk,
  ProjectMembershipEditor,
  ProjectMembershipEditorRef,
} from "./ProjectMembershipEditor";

const styles = StyleSheet.create({
  buttonsContainer: {
    paddingTop: spacings[24],
    paddingHorizontal: spacings[40],
    borderTopWidth: 1,
    borderTopColor: colors.gray[100],
  },
  root: {
    padding: spacings[40],
  },
});

type ContentsProps = {
  roleIdsByName: Record<Role, string>;
  editingMembership: ProjectMembershipFragment;
  currentUserMembershipId: string;
  canManageTeam: boolean;
  onDelete: () => void;
  onReceiveSave: () => void;
};

const EditMembersPanelContents = ({
  editingMembership,
  currentUserMembershipId,
  roleIdsByName,
  canManageTeam,
  onDelete,
  onReceiveSave,
}: ContentsProps) => {
  const [changeMembershipRole] = useMutation(ChangeMembershipRoleV2Document);

  const [addAdminConfirmModal, setAddAdminConfirmationModalInfo] = useState<Option<MembershipBulk>>(
    Option.None(),
  );

  const projectMembershipEditorRef = useRef<ProjectMembershipEditorRef>(null);
  const [membershipIdToDelete, setMembershipIdToDelete] = useState<Option<string>>(Option.None());

  const canDelete = canManageTeam && currentUserMembershipId !== editingMembership.id;
  const canSave =
    canManageTeam &&
    currentUserMembershipId !== editingMembership.id &&
    editingMembership.type !== "INVITED";

  const user = editingMembership.user;
  const email = editingMembership.email;

  const [isSaving, setIsSaving] = useState(false);

  const saveMembership = useCallback(
    (membershipBulk: MembershipBulk) => {
      const roleId = roleIdsByName[membershipBulk.role];

      setIsSaving(true);
      return changeMembershipRole({ input: { membershipId: editingMembership.id, roleId } })
        .mapOkToResult(data =>
          data.changeMembershipRoleV2 != null
            ? Result.Ok(data.changeMembershipRoleV2)
            : Result.Error(undefined),
        )
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(({ email }) => {
          if (email != null) {
            showToast({
              variant: "success",
              title: t("toast.success.memberEdit", {
                email,
              }),
            });
            onReceiveSave();
          }
        })
        .tapError(error => {
          showToast({ variant: "error", error, title: translateError(error) });
        })
        .tap(() => setIsSaving(false));
    },
    [changeMembershipRole, roleIdsByName, editingMembership, onReceiveSave],
  );

  const currentMemberRole = editingMembership.roleV2?.name ?? undefined;

  const onSave = useCallback(
    (membershipBulk: MembershipBulk) => {
      if (membershipBulk.role === "Admin" && currentMemberRole !== "Admin") {
        setAddAdminConfirmationModalInfo(
          Option.Some({
            emails: membershipBulk.emails,
            role: membershipBulk.role,
          }),
        );
      } else {
        saveMembership(membershipBulk);
      }
    },
    [saveMembership, currentMemberRole],
  );

  if (email == null) {
    return <ErrorView />;
  }

  return (
    <>
      <WithPartnerAccentColor color={colors.swan[500]}>
        <LakeScrollView style={styles.root}>
          <Box direction="row" alignItems="center">
            <Avatar user={user} size={32} />
            <Space width={12} />

            <LakeHeading level={2} variant="h3">
              {Option.fromNullable(user?.fullName).getOr(email)}
            </LakeHeading>
          </Box>

          <Space height={12} />

          <ProjectMembershipEditor
            readOnly={!canSave}
            invitationInProgress={editingMembership.type === "INVITED"}
            initialEditorState={{
              email,
              role: editingMembership.roleV2?.name as Role,
            }}
            onSave={onSave}
            ref={projectMembershipEditorRef}
          />
        </LakeScrollView>

        {canDelete || canSave ? (
          <Box
            direction="row"
            justifyContent="spaceBetween"
            alignItems="center"
            style={styles.buttonsContainer}
          >
            {canDelete && (
              <LakeButton
                mode="secondary"
                style={commonStyles.fill}
                color="negative"
                onPress={() => {
                  setMembershipIdToDelete(Option.Some(editingMembership.id));
                }}
              >
                {t("common.remove")}
              </LakeButton>
            )}

            {canSave && (
              <>
                <Space width={24} />

                <LakeButton
                  color="swan"
                  mode="primary"
                  onPress={() => {
                    if (projectMembershipEditorRef.current != null) {
                      projectMembershipEditorRef.current.submit();
                    }
                  }}
                  style={commonStyles.fill}
                  loading={isSaving}
                >
                  {t("common.save")}
                </LakeButton>
              </>
            )}
          </Box>
        ) : null}
      </WithPartnerAccentColor>

      <AddAdminConfirmationModal
        info={addAdminConfirmModal}
        onConfirm={membershipBulk =>
          saveMembership(membershipBulk).tapOk(() =>
            setAddAdminConfirmationModalInfo(Option.None()),
          )
        }
        onCancel={() => setAddAdminConfirmationModalInfo(Option.None())}
      />

      <DeleteMemberConfirmationModal
        membershipId={membershipIdToDelete}
        onCancel={() => setMembershipIdToDelete(Option.None())}
        onDelete={() => onDelete()}
      />
    </>
  );
};

type Props = {
  roleIdsByName: Record<Role, string>;
  editingMembershipId: string | null;
  currentUserMembershipId: string;
  projectMemberships: ProjectMembershipFragment[];
  canManageTeam: boolean;
  onDelete: () => void;
};

export const EditMembersPanel = ({
  roleIdsByName,
  editingMembershipId,
  currentUserMembershipId,
  projectMemberships,
  canManageTeam,
  onDelete,
}: Props) => {
  const { projectId } = useProjectInfo();

  return (
    <>
      <ListRightPanel
        keyExtractor={item => item.id}
        activeId={editingMembershipId}
        onActiveIdChange={editingMembershipId =>
          Router.push("ProjectAdminTeam", { projectId, editingMembershipId })
        }
        onClose={() => Router.push("ProjectAdminTeam", { projectId })}
        items={projectMemberships}
        render={editingMembership => {
          return (
            <EditMembersPanelContents
              editingMembership={editingMembership}
              currentUserMembershipId={currentUserMembershipId}
              roleIdsByName={roleIdsByName}
              canManageTeam={canManageTeam}
              onDelete={() => {
                Router.push("ProjectAdminTeam", { projectId });
                onDelete();
              }}
              onReceiveSave={() => {
                Router.push("ProjectAdminTeam", { projectId });
              }}
            />
          );
        }}
        closeLabel={t("common.close")}
        previousLabel={t("common.previous")}
        nextLabel={t("common.next")}
      />
    </>
  );
};
