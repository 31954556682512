import { AsyncData, Option, Result } from "@swan-io/boxed";
import { encodeSearch, pushUnsafe, useLocation } from "@swan-io/chicane";
import { ClientContext, ClientError, useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { TileGridPlaceholder, TilePlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { backgroundColor, colors, spacings } from "@swan-io/lake/src/constants/design";
import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import {
  ProjectActivationPlayWithSandbox,
  ProjectActivationPlayWithSandboxDataFetcher,
  SandboxData,
} from "../components/ProjectActivationPlayWithSandbox";
import { ProjectActivationReviewAndSignContracts } from "../components/ProjectActivationReviewAndSignContracts";
import { ProjectActivationTalkToAnExpertForm } from "../components/ProjectActivationTalkToAnExpertForm";
import { TrackPressable } from "../components/TrackPressable";
import {
  GetHomepageQuery,
  GetOAuthVisibleClientSecretDocument,
  GetOwnerOnboardingIdDocument,
  OnboardProjectOwnerDocument,
  ProjectStatus,
} from "../graphql/admin";
import { GetOnboardingDataDocument, GetOnboardingDataQuery } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import {
  liveExposedInternalByProjectIdClient,
  liveExposedInternalByProjectIdClient__projectMember,
  livePartnerByProjectIdClient,
} from "../utils/gql";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";

const styles = StyleSheet.create({
  container: {
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
    paddingLeft: spacings[96],
    paddingRight: spacings[48],
  },
  scrollView: {
    paddingRight: spacings[48],
  },
  contents: {
    flexGrow: 1,
  },
  tileContents: {
    flexShrink: 1,
  },
  tileLink: {
    display: "flex",
    flexDirection: "column",
  },
  disabledTileLink: {
    cursor: "default",
  },
  disabledTile: {
    opacity: 0.5,
  },
  close: {
    position: "absolute",
    right: "100%",
    transform: "translateX(-20px)",
    top: 40,
  },
});

type ProjectActivationTileProps = {
  icon: IconName;
  title: string;
  description: string;
  status: "ToDo" | "Pending" | "Disabled" | "Done";
  hovered?: boolean;
  hasLink?: boolean;
  linkIcon?: IconName;
};

const ProjectActivationTile = ({
  icon,
  title,
  description,
  status,
  hovered = false,
  hasLink = false,
  linkIcon = "chevron-right-filled",
}: ProjectActivationTileProps) => {
  return (
    <View style={status === "Disabled" ? styles.disabledTile : undefined}>
      <Tile hovered={status !== "Disabled" && hovered} paddingVertical={16}>
        <Box direction="row" alignItems="center">
          <Icon
            name={icon}
            size={24}
            color={match(status)
              .with("ToDo", () => colors.shakespear[500])
              .with("Pending", () => colors.warning[500])
              .with("Done", () => colors.positive[500])
              .with("Disabled", () => colors.gray[500])
              .exhaustive()}
          />

          <Space width={24} />

          <Box style={styles.tileContents}>
            <LakeHeading level={2} variant="h3">
              {title}
            </LakeHeading>

            <LakeText variant="regular" color={colors.gray[500]}>
              {description}
            </LakeText>
          </Box>

          <Fill />
          <Space width={16} />

          {match(status)
            .with("ToDo", () => <Tag color="shakespear">{t("projectActivation.status.todo")}</Tag>)
            .with("Pending", () => (
              <Tag color="warning">{t("projectActivation.status.pending")}</Tag>
            ))
            .with("Done", () => <Tag color="positive">{t("projectActivation.status.done")}</Tag>)
            .with("Disabled", () => (
              <Tag color="gray">{t("projectActivation.status.disabled")}</Tag>
            ))
            .exhaustive()}

          <Space width={24} />

          {hasLink ? (
            <Icon name={linkIcon} size={24} color={colors.gray[500]} />
          ) : (
            <Space width={24} />
          )}
        </Box>
      </Tile>
    </View>
  );
};

type Props = {
  // TODO: Stop using the complete object
  currentUser?: NonNullable<GetHomepageQuery["user"]>;
  projectName?: string;
  projectStatus: ProjectStatus;
  onPressClose: () => void;
  onProjectUpdate: () => void;
  contractSigned: boolean;
};

export const ProjectActivationPage = ({
  currentUser,
  projectStatus,
  projectName,
  onProjectUpdate,
  onPressClose,
  contractSigned,
}: Props) => {
  const [onboardProjectOwner, projectOwnerOnboarding] = useMutation(OnboardProjectOwnerDocument);

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  const { projectId } = useProjectInfo();
  const {
    raw: { path },
    search,
  } = useLocation();

  const [oAuthData] = useQuery(GetOAuthVisibleClientSecretDocument, { projectId, env: "Live" });

  const canOnboard = projectStatus !== "Initiated" && projectStatus !== "MeetingScheduled";

  const [data, { reload: reloadOnboardingId }] = useQuery(GetOwnerOnboardingIdDocument, {
    projectId,
  });

  const [sandboxData, setSandboxData] = useState<AsyncData<Result<SandboxData, ClientError>>>(() =>
    AsyncData.NotAsked(),
  );

  const onboardingId = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(data => Option.fromNullable(data.project.ownerOnboardingId))
    .toUndefined();

  // no need to listen projectId because changing projectId will change onboardingId
  // and listen projectId could run the effect with the onboadingId of previous selected project
  // biome-ignore lint/correctness/useExhaustiveDependencies(canOnboard):
  // biome-ignore lint/correctness/useExhaustiveDependencies(projectId):
  // biome-ignore lint/correctness/useExhaustiveDependencies(onboardProjectOwner):
  // biome-ignore lint/correctness/useExhaustiveDependencies(reloadOnboardingId):
  useEffect(() => {
    // if project hasn't ownerOnboardingId, we call onboardProjectOwner mutation to init it
    if (onboardingId == null && canOnboard) {
      onboardProjectOwner({
        input: {
          projectId,
        },
      }).tapOk(() => reloadOnboardingId());
    }
  }, [onboardingId]);

  const [onboarding, setOnboarding] = useState<GetOnboardingDataQuery["onboarding"]>();

  const fetchOnboarding = useCallback(() => {
    if (onboardingId != null) {
      void livePartnerByProjectIdClient(projectId)
        .query(GetOnboardingDataDocument, { onboardingId })
        .tapOk(onboarding => {
          setOnboarding(onboarding.onboarding);
        });
    }
  }, [projectId, onboardingId]);

  useEffect(() => {
    if (canOnboard && onboardingId != null) {
      fetchOnboarding();
    }
  }, [canOnboard, onboardingId, fetchOnboarding]);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("projectActivation.title"),
        link: "?activate=true",
      },
    ],
    [],
  );

  const [key] = useState(() => nanoid());

  return match(oAuthData)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), oAuthData => {
      const openLiveAccountStatus = match({
        project: projectStatus,
        holder: onboarding?.accountHolder?.verificationStatus ?? "NotStarted",
      })
        .returnType<"Disabled" | "ToDo" | "Pending" | "Done">()
        .with({ project: P.union("Initiated", "MeetingScheduled") }, () => "Disabled")
        .with({ project: "PendingCompliance", holder: "NotStarted" }, () => "ToDo")
        .with({ holder: P.union("Pending", "WaitingForInformation") }, () => "Pending")
        .otherwise(() => "Done");

      const reviewAndSignContractsStatus = match(projectStatus)
        .returnType<"Disabled" | "ToDo" | "Done">()
        .with(
          "PendingLiveReview",
          "LimitedLiveAccess",
          "BetaLiveAccess",
          "FullLiveAccess",
          "Enabled", // TEMP
          () => "Done",
        )
        .with(
          "PendingCompliance",
          "ToReview", // TEMP
          () => (contractSigned ? "Done" : "ToDo"),
        )
        .otherwise(() => "Disabled");

      const apiCredentialsStatus = match(projectStatus)
        .returnType<"Disabled" | "Done" | "ToDo">()
        .with(
          "PendingLiveReview",
          "LimitedLiveAccess",
          "BetaLiveAccess",
          "FullLiveAccess",
          "Enabled", // TEMP
          () => (oAuthData?.oAuthClient?.oAuthVisibleClientSecret != null ? "Done" : "ToDo"),
        )
        .otherwise(() => "Disabled");

      const talkToAnExpertStatus = match(projectStatus)
        .returnType<"ToDo" | "Pending" | "Done">()
        .with("Initiated", () => "ToDo")
        .with("MeetingScheduled", () => "Pending")
        .otherwise(() => "Done");

      const integrationReviewStatus = match(projectStatus)
        .returnType<"Disabled" | "ToDo" | "Pending" | "Done">()
        .with("PendingLiveReview", () => "Pending")
        .with("LimitedLiveAccess", () => "ToDo")
        .with(
          "BetaLiveAccess",
          "FullLiveAccess",
          "Enabled", // TEMP
          () => "Done",
        )
        .otherwise(() => "Disabled");

      return (
        <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
          <View style={styles.container}>
            <ProjectActivationPlayWithSandboxDataFetcher key={key} onData={setSandboxData} />

            <View style={styles.contents}>
              {match(search.step)
                .with(undefined, () => (
                  <TrackPressable action="Close project activation">
                    <LakeButton
                      style={styles.close}
                      mode="secondary"
                      onPress={onPressClose}
                      icon="lake-close"
                      size="small"
                      ariaLabel={t("common.close")}
                    />
                  </TrackPressable>
                ))
                .otherwise(() => (
                  <LakeButton
                    style={styles.close}
                    mode="secondary"
                    onPress={() => {
                      const { step, ...restOfSearch } = search;
                      pushUnsafe(`${path}${encodeSearch({ ...restOfSearch, activate: "true" })}`);
                    }}
                    icon="arrow-left-filled"
                    size="small"
                    ariaLabel={t("common.back")}
                  />
                ))}

              {match(search.step)
                .with(undefined, () => (
                  <LakeScrollView contentContainerStyle={styles.scrollView}>
                    <Space height={40} />
                    <Icon name="rocket-regular" size={64} color={colors.live.primary} />

                    {projectOwnerOnboarding.isLoading() ? (
                      <>
                        <Space height={72} />
                        <TilePlaceholder />
                        <Space height={24} />
                        <TilePlaceholder />
                      </>
                    ) : (
                      <>
                        <LakeHeading level={1} variant="h1">
                          {t("projectActivation.title")}
                        </LakeHeading>

                        <Space height={32} />

                        <Link
                          style={styles.tileLink}
                          to={`${path}${encodeSearch({ ...search, step: "PlayWithSandbox" })}`}
                        >
                          {({ hovered }) => (
                            <ProjectActivationTile
                              icon="flash-filled"
                              title={t("projectActivation.playWithSandbox")}
                              description={t("projectActivation.playWithSandboxDescription")}
                              status={sandboxData
                                .toOption()
                                .flatMap(data => data.toOption())
                                .map(values =>
                                  Object.values(values).every(item => item === true)
                                    ? "Done"
                                    : "ToDo",
                                )
                                .getOr("ToDo" as const)}
                              hovered={hovered}
                              hasLink={true}
                            />
                          )}
                        </Link>

                        <Space height={12} />

                        <Link
                          style={[
                            styles.tileLink,
                            (talkToAnExpertStatus === "Done" ||
                              talkToAnExpertStatus === "Pending") &&
                              styles.disabledTileLink,
                          ]}
                          disabled={
                            talkToAnExpertStatus === "Done" || talkToAnExpertStatus === "Pending"
                          }
                          to={`${path}${encodeSearch({ ...search, step: "TalkToAnExpert" })}`}
                        >
                          {({ hovered }) => (
                            <ProjectActivationTile
                              icon="person-call-filled"
                              title={t("projectActivation.talkToAnExpert")}
                              description={t("projectActivation.talkToAnExpertDescription")}
                              status={talkToAnExpertStatus}
                              hovered={hovered}
                              hasLink={talkToAnExpertStatus === "ToDo"}
                            />
                          )}
                        </Link>

                        <Space height={12} />

                        <Link
                          disabled={reviewAndSignContractsStatus === "Disabled"}
                          style={[
                            styles.tileLink,
                            reviewAndSignContractsStatus === "Disabled" && styles.disabledTileLink,
                          ]}
                          to={`${path}${encodeSearch({ ...search, step: "ReviewAndSignContracts" })}`}
                        >
                          {({ hovered }) => (
                            <ProjectActivationTile
                              icon="signature-filled"
                              title={t("projectActivation.reviewAndSignContracts")}
                              description={t("projectActivation.reviewAndSignContractsDescription")}
                              status={reviewAndSignContractsStatus}
                              hovered={hovered}
                              hasLink={true}
                            />
                          )}
                        </Link>

                        <Space height={12} />

                        <ProjectActivationTile
                          icon="approvals-app-filled"
                          title={t("projectActivation.complianceApproval")}
                          description={t("projectActivation.complianceApprovalDescription")}
                          status={match(projectStatus)
                            .with("PendingCompliance", "ToReview" /* TEMP */, () => {
                              const accountHolder = onboarding?.accountHolder;
                              if (
                                accountHolder?.verificationStatus === "NotStarted" ||
                                accountHolder?.verificationStatus === "Refused" ||
                                accountHolder?.verificationStatus === "Verified" ||
                                accountHolder?.verificationStatus === "WaitingForInformation"
                              ) {
                                return "Disabled" as const;
                              }
                              if (
                                isNullish(accountHolder) ||
                                accountHolder?.verificationStatus === "Pending"
                              ) {
                                return "ToDo" as const;
                              }

                              return "Pending" as const;
                            })
                            .with(
                              "PendingLiveReview",
                              "LimitedLiveAccess",
                              "BetaLiveAccess",
                              "FullLiveAccess",
                              "Enabled", // TEMP
                              () => "Done" as const,
                            )
                            .otherwise(() => "Disabled" as const)}
                        />

                        <Space height={12} />

                        <Link
                          disabled={
                            openLiveAccountStatus === "Disabled" ||
                            openLiveAccountStatus === "Done" ||
                            openLiveAccountStatus === "Pending"
                          }
                          style={[
                            styles.tileLink,
                            (openLiveAccountStatus === "Disabled" ||
                              openLiveAccountStatus === "Done" ||
                              openLiveAccountStatus === "Pending") &&
                              styles.disabledTileLink,
                          ]}
                          target="blank"
                          to={`${env.ONBOARDING_LIVE_URL}/projects/${projectId}/onboardings/${onboardingId}`}
                        >
                          {({ hovered }) => (
                            <ProjectActivationTile
                              icon="building-filled"
                              title={t("projectActivation.openCompanyLiveAccount")}
                              description={t("projectActivation.openCompanyLiveAccountDescription")}
                              status={openLiveAccountStatus}
                              hovered={hovered}
                              hasLink={
                                openLiveAccountStatus !== "Done" &&
                                openLiveAccountStatus !== "Pending"
                              }
                              linkIcon="open-filled"
                            />
                          )}
                        </Link>

                        <Space height={12} />

                        <Link
                          disabled={apiCredentialsStatus === "Disabled"}
                          style={[
                            styles.tileLink,
                            apiCredentialsStatus === "Disabled" && styles.disabledTileLink,
                          ]}
                          to={Router.DevelopersApi({ projectId, projectEnv: "live" })}
                        >
                          {({ hovered }) => (
                            <ProjectActivationTile
                              hovered={hovered}
                              icon="window-dev-tools-filled"
                              title={t("projectActivation.getLiveAPICredentials")}
                              description={t("projectActivation.getLiveAPICredentialsDescription")}
                              status={apiCredentialsStatus}
                              hasLink={true}
                            />
                          )}
                        </Link>

                        <Space height={12} />

                        <ProjectActivationTile
                          icon="preview-link-filled"
                          title={t("projectActivation.integrationReview")}
                          description={t("projectActivation.integrationReviewDescription")}
                          status={integrationReviewStatus}
                        />

                        <Space height={32} />
                      </>
                    )}

                    <Space height={48} />
                  </LakeScrollView>
                ))
                .with("PlayWithSandbox", () => (
                  <LakeScrollView contentContainerStyle={styles.scrollView}>
                    <Space height={40} />
                    <Icon name="flash-regular" size={64} color={colors.live.primary} />

                    {match(sandboxData)
                      .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
                        <TileGridPlaceholder />
                      ))
                      .with(AsyncData.P.Done(Result.P.Error(P.select())), error => (
                        <ErrorView error={error} />
                      ))
                      .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
                        <ProjectActivationPlayWithSandbox sandboxData={data} />
                      ))
                      .exhaustive()}

                    <Space height={40} />
                  </LakeScrollView>
                ))
                .with("TalkToAnExpert", () => (
                  <LakeScrollView contentContainerStyle={styles.scrollView}>
                    <Space height={40} />
                    <Icon name="person-call-regular" size={64} color={colors.live.primary} />

                    <ProjectActivationTalkToAnExpertForm
                      fullName={currentUser?.fullName ?? undefined}
                      email={
                        currentUser?.memberships?.find(item => item.project?.id === projectId)
                          ?.email ?? undefined
                      }
                      onSubmitSuccesful={() => {
                        const { step, ...nextSearch } = search;
                        pushUnsafe(`${path}${encodeSearch(nextSearch)}`);
                        onProjectUpdate();
                      }}
                    />

                    <Space height={40} />
                  </LakeScrollView>
                ))
                .with("ReviewAndSignContracts", () => (
                  <LakeScrollView contentContainerStyle={styles.scrollView}>
                    <Space height={40} />
                    <Icon name="signature-regular" size={64} color={colors.live.primary} />

                    <ClientContext.Provider
                      value={
                        shouldUseProjectMemberToken
                          ? liveExposedInternalByProjectIdClient__projectMember(projectId)
                          : liveExposedInternalByProjectIdClient(projectId)
                      }
                    >
                      <ProjectActivationReviewAndSignContracts
                        fullName={currentUser?.fullName ?? undefined}
                        projectName={projectName}
                        accountCountry={onboarding?.accountCountry ?? undefined}
                        tcuUrl={onboarding?.tcuUrl ?? undefined}
                      />
                    </ClientContext.Provider>

                    <Space height={40} />
                  </LakeScrollView>
                ))
                .otherwise(() => (
                  <ErrorView />
                ))}
            </View>
          </View>
        </BreadcrumbsRoot>
      );
    })
    .exhaustive();
};
