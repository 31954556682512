import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { useForm } from "@swan-io/use-form";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  IncomingCheckRejectionReasonCodeEnum,
  SimulateIncomingCheckRejectionDocument,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

type FormApi = {
  transactionId: string;
  reasonCode: IncomingCheckRejectionReasonCodeEnum | undefined;
};

const reasonCodes = deriveUnion<IncomingCheckRejectionReasonCodeEnum>({
  AmountMismatch: true,
  BeneficiaryMissingOrIncorrect: true,
  CheckNotReceived: true,
  CheckReceivedLate: true,
  DateInvalid: true,
  DateMissing: true,
  DebtorNameMissing: true,
  EndorsementMissing: true,
  InvalidOrMissingAmount: true,
  SignatureMissing: true,
}).array.map(value => ({ name: value, value }));

export const MerchantPaymentsChecksIncomingRejection = () => {
  const [mutate, simulation] = useMutation(SimulateIncomingCheckRejectionDocument);

  const result = simulation
    .mapOk(simulation => simulation.response)
    .mapOkToResult(response =>
      match(response)
        .with({ __typename: "SimulateIncomingCheckRejectionSuccessPayload" }, ({ transactionId }) =>
          Result.Ok([{ key: "transactionId", value: transactionId }]),
        )
        .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
    );

  const { Field, formStatus, submitForm } = useForm<FormApi>({
    transactionId: {
      initialValue: "",
      validate: validateRequired,
    },
    reasonCode: {
      initialValue: undefined,
    },
  });

  const onPressSubmit = () => {
    submitForm({
      onSuccess: values => {
        return Option.allFromDict(values)
          .map(({ transactionId, reasonCode }) => {
            mutate({
              input: {
                transactionId,
                reasonCode,
              },
            });
          })
          .toUndefined();
      },
    });
  };

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.merchantPayments.check.incomingRejection.description")}{" "}
            <DocumentationLink to="merchant">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="transactionId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.transactionId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.transactionIdPlaceholder")}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    valid={valid}
                    error={error}
                  />
                )}
              />
            )}
          </Field>

          <Field name="reasonCode">
            {({ value, error, onChange }) => (
              <LakeLabel
                label={`${t("simulatorForm.reasonCode")} *`}
                render={id => (
                  <LakeSelect
                    id={id}
                    value={value}
                    items={reasonCodes}
                    onValueChange={onChange}
                    error={error}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit merchant payment check incoming rejection">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onPressSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "transactionId",
              label: t("simulatorForm.transactionId"),
              placeholder: "-",
            },
          ]}
          results={result}
        />
      </Box>
    </Form>
  );
};
