import { AsyncData, Dict, Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Breadcrumbs, useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { Request, badStatusToError } from "@swan-io/request";
import { useForm } from "@swan-io/use-form";
import { useCallback, useMemo, useState } from "react";
import { P, match } from "ts-pattern";
import { ScheduleMeetingDocument } from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { validateRequired } from "../utils/validations";

type Props = {
  fullName?: string;
  email?: string;
  onSubmitSuccesful: () => void;
};

// 0-1: contact
// 0-2: company
// https://developers.hubspot.com/docs/api/crm/crm-custom-objects#:~:text=When%20creating%20a%20new%20association%20between%20two%20custom%20objects%2C%20specify%20the%20custom%20objects%20by%20their%20objectTypeId%20in%20the%C2%A0toObjectTypeId%20field.%20For%20standard%20objects%2C%20you%20can%20identify%20them%20by%20name%20or%20use%20the%20following%20values%3A%C2%A0
//  ¯\_(ツ)_/¯
const HUBSPOT_TYPES = {
  email: "0-1",
  linkedin_profile: "0-1",
  project_id: "0-2",
  company_name: "0-2",
  product_vision: "0-1",
};

export const ProjectActivationTalkToAnExpertForm = ({
  fullName,
  email,
  onSubmitSuccesful,
}: Props) => {
  const { projectId } = useProjectInfo();
  const [updateProjectStatus] = useMutation(ScheduleMeetingDocument);

  const [submission, setSubmission] = useState<
    AsyncData<Result<undefined, { errors: { errorType: string }[] } | undefined>>
  >(AsyncData.NotAsked());

  const { Field, submitForm } = useForm({
    email: {
      initialValue: email ?? "",
      validate: validateRequired,
    },
    linkedin_profile: {
      initialValue: "",
    },
    product_vision: {
      initialValue: "",
      validate: validateRequired,
    },
  });

  const onSubmitForm = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isNone()) {
          return;
        }

        setSubmission(AsyncData.Loading());
        Request.make({
          url: "https://api.hsforms.com/submissions/v3/integration/submit/8668360/56c087ed-3abb-4980-a6c2-ae401b4f4c34",
          method: "POST",
          type: "json",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            submittedAt: `${Date.now()}`,
            fields: [["project_id", projectId] as const, ...Dict.entries(option.get())].map(
              ([key, value]) => {
                return {
                  objectTypeId: HUBSPOT_TYPES[key],
                  name: key,
                  value,
                };
              },
            ),
          }),
        })
          .mapOkToResult(badStatusToError)
          .flatMapOk(() => updateProjectStatus({ input: { projectId } }))
          .mapOkToResult(data =>
            Option.fromNullable(data.updateProjectStatusMeetingScheduled).toResult(
              new Error("No response"),
            ),
          )
          .mapOkToResult(filterRejectionsToResult)
          .tapOk(() => {
            onSubmitSuccesful();
          });
      },
    });
  }, [submitForm, projectId, updateProjectStatus, onSubmitSuccesful]);

  useCrumb(
    useMemo(() => {
      return {
        label: t("projectActivation.talkToAnExpert"),
        link: "?activate=true&step=TalkToAnExpert",
      };
    }, []),
  );

  return (
    <>
      <Breadcrumbs />
      <Space height={40} />

      <Tile
        title={
          fullName != null
            ? t("projectActivationTalkToAnExpert.hi", { fullName })
            : t("projectActivationTalkToAnExpert.hiNoName")
        }
      >
        <LakeText variant="semibold" color={colors.gray[900]}>
          {t("projectActivationTalkToAnExpert.complianceReviewTitle")}
        </LakeText>

        <LakeText>{t("projectActivationTalkToAnExpert.complianceReviewDescription")}</LakeText>
      </Tile>

      <Space height={24} />

      <Tile>
        <LakeLabel
          label={t("projectActivationTalkToAnExpert.professionalEmail")}
          render={id => (
            <Field name="email">
              {({ value, onChange, error }) => (
                <LakeTextInput
                  error={match(submission)
                    .with(
                      AsyncData.P.Done(Result.P.Error(P.select(P.nonNullable))),
                      ({ errors }) =>
                        errors.some(item => item.errorType === "INVALID_EMAIL")
                          ? t("common.form.invalidEmail")
                          : error,
                    )
                    .otherwise(() => error)}
                  placeholder={t("projectActivationTalkToAnExpert.professionalEmailPlaceholder")}
                  id={id}
                  defaultValue={value}
                  onChangeText={text => {
                    setSubmission(AsyncData.NotAsked());
                    onChange(text);
                  }}
                />
              )}
            </Field>
          )}
        />

        <LakeLabel
          label={t("projectActivationTalkToAnExpert.linkedInProfile")}
          render={id => (
            <Field name="linkedin_profile">
              {({ value, onChange }) => (
                <LakeTextInput
                  placeholder={t("projectActivationTalkToAnExpert.linkedInProfilePlaceholder")}
                  id={id}
                  defaultValue={value}
                  onChangeText={onChange}
                />
              )}
            </Field>
          )}
        />

        <LakeLabel
          label={t("projectActivationTalkToAnExpert.apiUsage")}
          render={id => (
            <Field name="product_vision">
              {({ value, onChange, error }) => (
                <LakeTextInput
                  placeholder={t("projectActivationTalkToAnExpert.apiUsagePlaceholder")}
                  id={id}
                  multiline={true}
                  defaultValue={value}
                  onChangeText={onChange}
                  rows={3}
                  error={error}
                />
              )}
            </Field>
          )}
        />
      </Tile>

      <LakeButtonGroup>
        <LakeButton
          loading={submission.isLoading()}
          size="large"
          color="current"
          icon="mail-filled"
          onPress={onSubmitForm}
        >
          {t("common.send")}
        </LakeButton>
      </LakeButtonGroup>
    </>
  );
};
