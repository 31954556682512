import { Array, Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import {
  Document,
  SupportingDocumentCollection,
  SupportingDocumentCollectionRef,
} from "@swan-io/shared-business/src/components/SupportingDocumentCollection";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { Fragment, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { match, P } from "ts-pattern";
import {
  GenerateSupportingDocumentUploadUrlDocument,
  RequestSupportingDocumentCollectionReviewDocument,
  SupportingDocumentCollectionConnectionFragment,
  SupportingDocumentCollectionFragment,
  SupportingDocumentCollectMode,
  SupportingDocumentPurposeEnum,
} from "../graphql/partner";
import { t } from "../utils/i18n";

const styles = StyleSheet.create({
  column: {
    flexBasis: "50%",
    flexShrink: 1,
  },
  list: {
    listStyleType: "disc",
  },
  listItem: {
    display: "list-item",
    listStyleType: "disc",
    marginLeft: "1em",
  },
});

type CollectionProps = {
  supportingDocumentCollection: SupportingDocumentCollectionFragment;
  supportingDocumentCollectMode: SupportingDocumentCollectMode | undefined;
};

const Collection = ({
  supportingDocumentCollection,
  supportingDocumentCollectMode,
}: CollectionProps) => {
  const { id, requiredSupportingDocumentPurposes, statusInfo, supportingDocuments } =
    supportingDocumentCollection;
  const [generateSupportingDocument] = useMutation(GenerateSupportingDocumentUploadUrlDocument);
  const [confirmReviewOpened, setConfirmReviewOpened] = useState(false);

  const [requestSupportingDocumentCollectionReview, requestSupportingDocumentCollectionReviewData] =
    useMutation(RequestSupportingDocumentCollectionReviewDocument);

  const supportingDocumentCollectionRef =
    useRef<SupportingDocumentCollectionRef<SupportingDocumentPurposeEnum>>(null);

  const startReview = () => {
    requestSupportingDocumentCollectionReview({ input: { supportingDocumentCollectionId: id } })
      .mapOk(data => data.requestSupportingDocumentCollectionReview)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        setConfirmReviewOpened(false);
        showToast({ variant: "success", title: t("toast.success.documentTransmitted") });
      })
      .tapError(error => showToast({ variant: "error", title: translateError(error) }));
  };
  const onSubmit = () => {
    const supportingDocumentCollection = supportingDocumentCollectionRef.current;
    if (supportingDocumentCollection == null) {
      return;
    }
    if (supportingDocumentCollection.areAllRequiredDocumentsFilled()) {
      startReview();
    } else {
      setConfirmReviewOpened(true);
    }
  };

  const docs = Array.filterMap(supportingDocuments, document =>
    match(document)
      .returnType<Option<Document<SupportingDocumentPurposeEnum>>>()
      .with(P.nullish, () => Option.None())
      .with({ statusInfo: { __typename: "SupportingDocumentNotUploadedStatusInfo" } }, () =>
        Option.None(),
      )
      .with(
        {
          statusInfo: {
            __typename: "SupportingDocumentWaitingForUploadStatusInfo",
          },
        },
        () => Option.None(),
      )
      .with({ statusInfo: { __typename: "SupportingDocumentValidatedStatusInfo" } }, document =>
        Option.Some({
          purpose: document.supportingDocumentPurpose,
          file: {
            id: document.id,
            name: document.statusInfo.filename,
            statusInfo: { status: "Validated" },
          },
        }),
      )
      .with({ statusInfo: { __typename: "SupportingDocumentRefusedStatusInfo" } }, document =>
        Option.Some({
          purpose: document.supportingDocumentPurpose,
          file: {
            id: document.id,
            name: document.statusInfo.filename,
            statusInfo: {
              status: "Refused",
              reason: document.statusInfo.reason,
              reasonCode: document.statusInfo.reasonCode,
            },
          },
        }),
      )
      .with({ statusInfo: { __typename: "SupportingDocumentUploadedStatusInfo" } }, document =>
        Option.Some({
          purpose: document.supportingDocumentPurpose,
          file: {
            id: document.id,
            name: document.statusInfo.filename,
            statusInfo: { status: "Uploaded" },
          },
        }),
      )
      .exhaustive(),
  );

  return (
    <>
      <Box direction="row" alignItems="start">
        <Tile title={t("transaction.supportingDocumentCollection")} style={styles.column}>
          <ReadOnlyFieldList>
            <LakeLabel
              type="view"
              label={t("transaction.supportingDocumentCollection.id")}
              render={() => <LakeText color={colors.gray[900]}>{id}</LakeText>}
              actions={
                <LakeCopyButton
                  valueToCopy={id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            <LakeLabel
              type="view"
              label={t("transaction.supportingDocumentCollection.status")}
              render={() =>
                match(statusInfo.status)
                  .with("Approved", () => <Tag color="positive">{statusInfo.status}</Tag>)
                  .with("Canceled", () => (
                    <Tag color="gray">{t("supportingDocumentCollection.status.Canceled")}</Tag>
                  ))
                  .with("WaitingForDocument", () => (
                    <Tag color="warning">
                      {t("supportingDocumentCollection.status.WaitingForDocument")}
                    </Tag>
                  ))
                  .with("PendingReview", () => (
                    <Tag color="shakespear">
                      {t("supportingDocumentCollection.status.PendingReview")}
                    </Tag>
                  ))
                  .with("Rejected", () => (
                    <Tag color="negative">{t("supportingDocumentCollection.status.Rejected")}</Tag>
                  ))
                  .exhaustive()
              }
            />

            {requiredSupportingDocumentPurposes.length > 0 &&
            supportingDocumentCollectMode !== "API" ? (
              <LakeLabel
                type="view"
                label={t("transaction.supportingDocumentCollection.requestedSupportingDocuments")}
                render={() => (
                  <View role="list" style={styles.list}>
                    {requiredSupportingDocumentPurposes.map(purpose => (
                      <View role="listitem" style={styles.listItem} key={purpose.name}>
                        <LakeText color={colors.gray[900]}>
                          {capitalize(
                            purpose.name
                              .replace(
                                /([A-Z])([a-z])/g,
                                (_, $1, $2) => ` ${String($1).toLowerCase()}${$2}`,
                              )
                              .trim(),
                          )}
                        </LakeText>
                      </View>
                    ))}
                  </View>
                )}
              />
            ) : null}
          </ReadOnlyFieldList>
        </Tile>

        <Space width={24} />

        <View style={styles.column}>
          <Tile title={t("transaction.supportingDocument")}>
            <SupportingDocumentCollection
              ref={supportingDocumentCollectionRef}
              generateUpload={({
                fileName,
                purpose,
              }: {
                fileName: string;
                purpose: SupportingDocumentPurposeEnum;
              }) =>
                generateSupportingDocument({
                  input: {
                    filename: fileName,
                    supportingDocumentPurpose: purpose,
                    supportingDocumentCollectionId: id,
                  },
                })
                  .mapOk(data => data.generateSupportingDocumentUploadUrl)
                  .mapOkToResult(filterRejectionsToResult)
                  .mapOk(({ upload, supportingDocumentId }) => ({
                    upload,
                    id: supportingDocumentId,
                  }))
              }
              status={statusInfo.status}
              showIds={true}
              documents={docs}
              requiredDocumentPurposes={requiredSupportingDocumentPurposes.map(item => item.name)}
            />
          </Tile>

          {statusInfo.status === "WaitingForDocument" && (
            <LakeButtonGroup paddingBottom={0}>
              <LakeButton
                color="current"
                onPress={onSubmit}
                loading={requestSupportingDocumentCollectionReviewData.isLoading()}
              >
                {t("transaction.supportingDocumentCollection.button.requestReview")}
              </LakeButton>
            </LakeButtonGroup>
          )}
        </View>
      </Box>

      <LakeModal
        icon="warning-regular"
        title={t("supportingDocument.confirmRequestReview.title")}
        visible={confirmReviewOpened}
      >
        <>
          <LakeText color={colors.gray[900]}>
            {t("supportingDocument.confirmRequestReview.description")}
          </LakeText>

          <Space height={32} />

          <LakeButtonGroup paddingBottom={0}>
            <LakeButton
              color="gray"
              mode="secondary"
              onPress={() => setConfirmReviewOpened(false)}
              grow={true}
            >
              {t("common.cancel")}
            </LakeButton>

            <LakeButton
              color="current"
              onPress={startReview}
              loading={requestSupportingDocumentCollectionReviewData.isLoading()}
              grow={true}
            >
              {t("common.confirm")}
            </LakeButton>
          </LakeButtonGroup>
        </>
      </LakeModal>
    </>
  );
};

type Props = {
  supportingDocumentCollections: SupportingDocumentCollectionConnectionFragment;
  supportingDocumentCollectMode: SupportingDocumentCollectMode | undefined;
};

export const TransactionsDetailSupportingDocumentCollections = ({
  supportingDocumentCollections: { edges, totalCount },
  supportingDocumentCollectMode,
}: Props) => {
  return totalCount === 0 ? (
    <Box alignItems="center" justifyContent="center" grow={1}>
      <EmptyView
        icon="lake-inbox-empty"
        title={t("transaction.supportingDocumentCollectionEmpty")}
      />
    </Box>
  ) : (
    <LakeScrollView>
      {edges.map(({ node }, index) => {
        return (
          <Fragment key={node.id}>
            {index > 0 ? <Separator space={32} /> : null}

            <Collection
              supportingDocumentCollection={node}
              supportingDocumentCollectMode={supportingDocumentCollectMode}
            />
          </Fragment>
        );
      })}

      <Space height={24} />
    </LakeScrollView>
  );
};
