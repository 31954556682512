import { DatePickerDate } from "@swan-io/shared-business/src/components/DatePicker";
import { isValidEmail } from "@swan-io/shared-business/src/utils/validation";
import { Validator, combineValidators } from "@swan-io/use-form";
import dayjs from "dayjs";
import iban from "iban";
import { isIP } from "is-ip";
import { P, isMatching, match } from "ts-pattern";
import { locale, t } from "./i18n";

export const validateNullableRequired: Validator<string | undefined> = value => {
  if (value == null || !value) {
    return t("common.form.required");
  }
};

export const validateRequired: Validator<string> = value => {
  if (!value) {
    return t("common.form.required");
  }
};

export const validateFileRequired: Validator<File | undefined> = value => {
  if (!value || value == null) {
    return t("common.form.required");
  }
};

export const validateRequiredArray: Validator<unknown[]> = value => {
  if (!value.length) {
    return t("common.form.required");
  }
};

export const validateEmail: Validator<string> = combineValidators<string>(
  validateRequired,
  value => {
    if (!isValidEmail(value)) {
      return t("common.form.invalidEmail");
    }
  },
);

export const validateUrl: Validator<string> = combineValidators<string>(validateRequired, value => {
  const [protocol, remainingUrl] = value.split("://");
  const regexProtocol = /^(http|https)$/g; // Switch to /^[A-Za-z][A-Za-z0-9+-.]{0,}$/ if we support PKCE one day // https://www.ietf.org/rfc/rfc2396.txt
  const regexRemainingUrl = /^[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]+$/g;

  // <string>.split("://")[0] always returns a string
  if (!regexProtocol.test(protocol as string)) {
    return t("common.form.url.invalidProtocol");
  }

  // If <string> includes "://", then <string>.split("://")[1] will always returns a string
  if (!value.includes("://") || !regexRemainingUrl.test(remainingUrl as string)) {
    return t("common.form.url.invalidFormat");
  }
});

export const validateDate: Validator<string> = combineValidators<string>(
  validateRequired,
  value => {
    if (!dayjs(value, locale.dateFormat, true).isValid()) {
      return t("common.form.invalidDate");
    }
  },
);

export const validateTime: Validator<string> = combineValidators<string>(
  validateRequired,
  value => {
    if (!dayjs(value, locale.timeFormat, true).isValid()) {
      return t("common.form.invalidTime");
    }
  },
);

const HEX_COLOR_RE = /^[a-fA-F0-9]{6}$/;

export const validateHexColor: Validator<string> = value => {
  if (!HEX_COLOR_RE.test(value)) {
    return t("common.form.invalidHexColor");
  }
};

export const validateIban: Validator<string> = combineValidators<string>(
  validateRequired,
  value => {
    if (!iban.isValid(value)) {
      return t("common.form.invalidIban");
    }
  },
);

export const validateId: Validator<string> = value => {
  const regex =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  if (!regex.test(value)) {
    return t("common.form.invalidId");
  }
};

export const validateNumeric = (params?: { min?: number; max?: number }): Validator<string> =>
  combineValidators<string>(validateRequired, value => {
    // first regex test integer and the second one test float
    if (!/^-?\d+$/.test(value) && !/^-?\d+\.\d+$/.test(value)) {
      return t("common.form.number");
    }
    const parsed = Number.parseFloat(value);
    if (params?.min != null && parsed < params.min) {
      return t("common.form.number.upperThan", { value: params.min });
    }
    if (params?.max != null && parsed > params.max) {
      return t("common.form.number.lowerThan", { value: params.max });
    }
  });

const isValidPublicKey = isMatching({
  crv: "P-256" as const,
  kty: "EC" as const,
  key_ops: P.array(P.string),
});

export const validatePublicKey: Validator<string> = combineValidators<string>(
  validateRequired,
  value => {
    try {
      const json = JSON.parse(value) as unknown;

      if (!isValidPublicKey(json) || !json.key_ops.includes("verify")) {
        return t("common.form.invalidPublicKey");
      }
    } catch {
      // If json parsing fails
      return t("common.form.invalidPublicKey");
    }
  },
);

export const validateIpAddress: Validator<string> = value => {
  if (!isIP(value)) {
    return t("common.form.invalidIpAddress");
  }
};

export const validateUuid: Validator<string> = value => {
  const validUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/gi;
  if (value && !validUuid.test(value)) {
    return t("common.form.invalidUuid");
  }
};

export const isAfterUpdatedAtSelectable = (date: DatePickerDate, filters: unknown) => {
  return match(filters)
    .with({ isBeforeUpdatedAt: P.string }, ({ isBeforeUpdatedAt }) => {
      const isAfterUpdatedAt = dayjs()
        .date(date.day)
        .month(date.month)
        .year(date.year)
        .endOf("day");

      const isBeforeUpdatedAtDate = dayjs(isBeforeUpdatedAt).startOf("day");
      return isAfterUpdatedAt.isBefore(isBeforeUpdatedAtDate);
    })
    .otherwise(() => true);
};

// value comes from input text above the datepicker
// so the format of value is locale.dateFormat
export const validateAfterUpdatedAt = (value: string, filters: unknown) => {
  return (
    validateDate(value) ??
    match(filters)
      .with({ isBeforeUpdatedAt: P.string }, ({ isBeforeUpdatedAt }) => {
        const isAfterUpdatedAt = dayjs(value, locale.dateFormat).endOf("day");
        const isBeforeUpdatedAtDate = dayjs(isBeforeUpdatedAt).startOf("day");

        if (!isAfterUpdatedAt.isBefore(isBeforeUpdatedAtDate)) {
          const updatedBefore = isBeforeUpdatedAtDate.format("LL");
          return t("common.form.chooseDateBefore", { date: updatedBefore });
        }
      })
      .otherwise(() => undefined)
  );
};

export const isBeforeUpdatedAtSelectable = (date: DatePickerDate, filters: unknown) => {
  return match(filters)
    .with({ isAfterUpdatedAt: P.string }, ({ isAfterUpdatedAt }) => {
      const isBeforeUpdatedAt = dayjs()
        .date(date.day)
        .month(date.month)
        .year(date.year)
        .startOf("day");

      const isAfterUpdatedAtDate = dayjs(isAfterUpdatedAt).endOf("day");
      return isBeforeUpdatedAt.isAfter(isAfterUpdatedAtDate);
    })
    .otherwise(() => true);
};

// value comes from input text above the datepicker
// so the format of value is locale.dateFormat
export const validateBeforeUpdatedAt = (value: string, filters: unknown) => {
  return (
    validateDate(value) ??
    match(filters)
      .with({ isAfterUpdatedAt: P.string }, ({ isAfterUpdatedAt }) => {
        const isBeforeUpdatedAt = dayjs(value, locale.dateFormat).startOf("day");
        const isAfterUpdatedAtDate = dayjs(isAfterUpdatedAt).endOf("day");

        if (!isBeforeUpdatedAt.isAfter(isAfterUpdatedAtDate)) {
          const updatedAfter = isAfterUpdatedAtDate.format("LL");
          return t("common.form.chooseDateAfter", { date: updatedAfter });
        }
      })
      .otherwise(() => undefined)
  );
};
