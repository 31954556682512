import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import {
  backgroundColor,
  colors,
  negativeSpacings,
  radii,
  spacings,
  texts,
} from "@swan-io/lake/src/constants/design";
import { StyleSheet } from "react-native";
import { EnvType } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router, projectSectionsRoutes } from "../utils/routes";
import { EnvironmentSelector } from "./EnvironmentSelector";
import { ProjectEnvBadge } from "./ProjectEnvBadge";

const TRANSPARENT = "transparent";

const styles = StyleSheet.create({
  container: {
    height: 64,
    borderTopWidth: 3,
    borderTopColor: TRANSPARENT,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray[100],
  },
  sandboxContainer: {
    borderTopColor: colors.current.primary,
  },
  wrapper: {
    width: "100%",
    paddingHorizontal: 20,
    maxWidth: 2560,
    marginHorizontal: "auto",
  },
  link: {
    ...texts.semibold,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: spacings[16],
    paddingVertical: spacings[8],
    color: colors.gray[600],
    borderRadius: radii[6],
    marginRight: spacings[8],
  },
  hoveredLink: {
    backgroundColor: colors.gray[100],
  },
  pressedLink: {
    backgroundColor: colors.gray[200],
  },
  activeLink: {
    backgroundColor: colors.current.primary,
    color: colors.current.contrast,
  },
  disabledLink: {
    opacity: 0.5,
  },
  newBadge: {
    backgroundColor: colors.darkPink[500],
    position: "absolute",
    top: negativeSpacings[8],
    right: negativeSpacings[8],
    borderRadius: radii[4],
    lineHeight: "1.4" as unknown as number,
    paddingHorizontal: spacings[4],
    borderWidth: 1,
    borderColor: backgroundColor.default,
    fontSize: 12,
  },
});

const ProjectNavigationLink = ({
  isActive = false,
  href,
  icon,
  iconActive,
  text,
  isNew = false,
  disabled,
}: {
  isActive: boolean;
  href: string;
  icon: IconName;
  isNew?: boolean;
  iconActive: IconName;
  text: string;
  disabled: boolean;
}) => {
  return (
    <Link
      to={href}
      disabled={disabled}
      style={({ hovered, pressed }) => [
        styles.link,
        !disabled && hovered && styles.hoveredLink,
        !disabled && pressed && styles.pressedLink,
        isActive && styles.activeLink,
        disabled && styles.disabledLink,
      ]}
    >
      <Icon name={isActive ? iconActive : icon} size={16} />
      <Space width={4} />

      <LakeText variant="semibold" color={isActive ? colors.current.contrast : colors.gray[600]}>
        {text}
      </LakeText>

      {isNew ? (
        <LakeText style={styles.newBadge} color="#fff" variant="smallMedium">
          {t("common.new")}
        </LakeText>
      ) : null}
    </Link>
  );
};

type Props = {
  disabled?: boolean;
  onProjectEnvChange: (envType: EnvType) => void;
};

export const ProjectNavigation = ({ disabled = false, onProjectEnvChange }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(projectSectionsRoutes);

  return (
    <Box
      justifyContent="center"
      style={[styles.container, projectEnv === "sandbox" && styles.sandboxContainer]}
    >
      <Box direction="row" alignItems="center" style={styles.wrapper}>
        <Box role="navigation" direction="row">
          <ProjectNavigationLink
            isActive={route?.name === "DataArea"}
            disabled={disabled}
            href={Router.UserList({ projectId, projectEnv })}
            icon="database-regular"
            iconActive="database-filled"
            text={t("project.data")}
          />

          <ProjectNavigationLink
            isActive={route?.name === "InsightsArea"}
            disabled={disabled}
            href={Router.InsightsOverview({ projectId, projectEnv })}
            icon="board-regular"
            iconActive="board-filled"
            text={t("project.insights")}
          />

          <ProjectNavigationLink
            isActive={route?.name === "DevelopersArea"}
            disabled={disabled}
            href={Router.DevelopersApi({ projectId, projectEnv })}
            icon="window-dev-tools-regular"
            iconActive="window-dev-tools-filled"
            text={t("project.developers")}
          />

          <ProjectNavigationLink
            isActive={route?.name === "SettingsArea"}
            disabled={disabled}
            href={Router.SettingsRoot({ projectId, projectEnv })}
            icon="settings-regular"
            iconActive="settings-filled"
            text={t("project.settings")}
          />
        </Box>

        <Fill minWidth={24} />
        <ProjectEnvBadge />
        <Space width={24} />
        <EnvironmentSelector onSelect={onProjectEnvChange} />
      </Box>
    </Box>
  );
};
