import { LakeText } from "@swan-io/lake/src/components/LakeText";
import {
  Defs,
  Image,
  Path,
  RadialGradient,
  Rect,
  Stop,
  Svg,
} from "@swan-io/lake/src/components/Svg";
import { invariantColors } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import customCardImage from "../assets/img/custom-card-image.jpg";

const styles = StyleSheet.create({
  fullName: {
    fontSize: 12,
    position: "absolute",
    left: 40,
    bottom: 28,
  },
});

export type CardColor = "Silver" | "Black" | "Custom";

export type CardBackground =
  | { color: "Silver" }
  | { color: "Black" }
  | { color: "Custom"; imageUrl?: string; textColor?: string };

type Props = {
  background: CardBackground;
  fullName?: string;
};

const FULL_NAME_FALLBACK = "Jonny Driver";

export const CreditCard = ({ background, fullName }: Props) => {
  const textColor = match(background)
    .with({ color: "Black" }, () => invariantColors.white)
    .with({ color: "Silver" }, () => invariantColors.black)
    .with({ color: "Custom" }, ({ textColor }) => textColor ?? invariantColors.white)
    .exhaustive();

  return (
    <View>
      <LakeText style={[styles.fullName, { color: textColor }]}>
        {fullName ?? FULL_NAME_FALLBACK}
      </LakeText>

      <Svg viewBox="0 0 85 55">
        <Defs>
          <RadialGradient
            cx="35%"
            cy="4%"
            fx="35%"
            fy="4%"
            r="130%"
            gradientTransform="matrix(.50992 .6156 -.39833 .78806 .19 -.2)"
            id="light"
          >
            <Stop stopColor="#F4F4F4" offset="0%" />
            <Stop stopColor="#C1C1C1" offset="100%" />
          </RadialGradient>

          <RadialGradient
            cx="35%"
            cy="4%"
            fx="35%"
            fy="4%"
            r="130%"
            gradientTransform="matrix(.50992 .6156 -.39833 .78806 .19 -.2)"
            id="dark"
          >
            <Stop stopColor="#41494A" offset="0%" />
            <Stop stopColor="#1F2121" offset="100%" />
          </RadialGradient>
        </Defs>

        {match(background)
          .with({ color: "Black" }, () => <Rect fill="url(#dark)" width={85} height={55} rx={3} />)
          .with({ color: "Silver" }, () => (
            <Rect fill="url(#light)" width={85} height={55} rx={3} />
          ))
          .with({ color: "Custom" }, ({ imageUrl }) => (
            <Image xlinkHref={imageUrl ?? customCardImage} width={85} height={55} />
          ))
          .exhaustive()}

        {background.color !== "Custom" && (
          <>
            <Path fill="#FF5F00" d="M70.85 42.25h4.71v8.46h-4.71z" />

            <Path
              d="M71.15 46.48c0-1.72.81-3.24 2.05-4.23a5.38 5.38 0 100 8.46 5.37 5.37 0 01-2.05-4.23z"
              fill="#EB001B"
            />

            <Path
              d="M81.92 46.48a5.38 5.38 0 01-8.7 4.23 5.4 5.4 0 000-8.46 5.39 5.39 0 018.7 4.23z"
              fill="#F79E1B"
            />
          </>
        )}

        <Path
          d="M18.83 18.43c.36 0 .7.13.94.32.24.2.4.47.4.77h0v5.96a1 1 0 01-.4.77c-.24.2-.58.32-.94.32h0-8.27c-.36 0-.7-.13-.93-.32a1 1 0 01-.4-.77h0v-5.96a1 1 0 01.4-.77c.24-.2.57-.32.93-.32h0z"
          stroke="#B4B4B4"
          strokeWidth={0.13}
          fill="#D9D9D9"
        />

        <Path d="M12.29 18.5v1.98h.06c.54 0 1 .33 1.05.76v.11h2.58v-.02c0-.43.42-.8.94-.84h.18V18.5h.14v1.98h2.86v.11h-3.05c-.48 0-.87.28-.92.65l-.01.1v1.1h3.98v.11h-3.98v1.12c0 .37.35.69.8.73l.13.01h3.05v.1h-2.86v1.99h-.14v-1.98h-.05c-.55 0-1-.33-1.06-.76v-.11H13.4v.02c0 .43-.41.8-.94.84h-.18v1.99h-.13v-1.98H9.3v-.11h3.05c.47 0 .86-.28.92-.65v-1.2H9.3v-.11h3.98v-1.12c0-.37-.36-.69-.82-.73l-.11-.01H9.3v-.1h2.86V18.5h.13zm3.7 2.96H13.4v2.08h2.58v-2.08z" />
      </Svg>
    </View>
  );
};
