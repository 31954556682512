import { AsyncData, Option, Result } from "@swan-io/boxed";
import { ClientContext, useMutation, useQuery } from "@swan-io/graphql-client";
import { Avatar } from "@swan-io/lake/src/components/Avatar";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useState } from "react";
import { View } from "react-native";
import { P, match } from "ts-pattern";
import {
  GetServerConsentProjectSettingsDocument,
  UpdateServerConsentProjectSettingsDocument,
  UpdateServerConsentProjectSettingsInput,
} from "../graphql/admin";
import { GetLegalRepresentativeDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import {
  livePartnerByProjectIdClient,
  livePartnerByProjectIdClient__projectMember,
} from "../utils/gql";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";
import { ErrorView } from "./ErrorView";
import { ServerConsentForm } from "./ServerConsentForm";
import { TrackPressable } from "./TrackPressable";

type PropsConfirmModal = {
  onClose: () => void;
  onPressSave: () => void;
  onboardingId: string;
  loading: boolean;
};

const ConfirmModal = ({ onClose, onPressSave, onboardingId, loading }: PropsConfirmModal) => {
  const [data] = useQuery(GetLegalRepresentativeDocument, { onboardingId });

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ onboarding }) => {
      const user = onboarding.account?.legalRepresentativeMembership.user;

      return (
        <View>
          <Space height={12} />
          <LakeText>{t("serverConsent.confirm.text")}</LakeText>
          <Space height={24} />

          <Box direction="row" justifyContent="start" alignItems="center">
            <Avatar size={55} user={user} />
            <Space width={24} />

            <Box>
              <LakeHeading level={1} variant="h5">
                {user?.fullName}
              </LakeHeading>

              <Space height={8} />

              <LakeText variant="light">
                {onboarding.account?.legalRepresentativeMembership.email} |
                {onboarding.account?.legalRepresentativeMembership.user?.mobilePhoneNumber}
              </LakeText>
            </Box>
          </Box>

          <Space height={24} />

          <LakeButtonGroup>
            <TrackPressable action="Cancel server consent draft">
              <LakeButton mode="secondary" color="gray" onPress={onClose} grow={true}>
                {t("common.cancel")}
              </LakeButton>
            </TrackPressable>

            <TrackPressable action="Confirm server consent draft">
              <LakeButton
                grow={true}
                color="live"
                onPress={() => {
                  onPressSave();
                  onClose();
                }}
                loading={loading}
              >
                {t("serverConsent.confirm.validate")}
              </LakeButton>
            </TrackPressable>
          </LakeButtonGroup>
        </View>
      );
    })
    .exhaustive();
};

export const ServerConsentDraftTab = () => {
  const { projectId, projectEnv } = useProjectInfo();

  const [data] = useQuery(GetServerConsentProjectSettingsDocument, {
    projectId,
    env: capitalize(projectEnv),
  });

  const [updateServerConsentProjectSettings, update] = useMutation(
    UpdateServerConsentProjectSettingsDocument,
  );

  const onSubmit = (values: UpdateServerConsentProjectSettingsInput) => {
    updateServerConsentProjectSettings({ input: values })
      .mapOk(data => data.updateServerConsentProjectSettings)
      .tapOk(data => {
        match(data.__typename)
          .with("UpdateServerConsentProjectSettingsSuccessPayload", () => {
            showToast({ variant: "success", title: t("toast.success.serverConsentSaved") });
          })
          .with("UpdateServerConsentProjectSettingsSuccessConsentPayload", () => {
            showToast({ variant: "success", title: t("toast.success.serverConsentSaved") });
            Router.push("DevelopersServerConsentPending", { projectId, projectEnv });
          })
          .with(
            "ForbiddenRejection",
            "PendingConsentProjectSettingsAlreadyExistsRejection",
            "ProjectNotFound",
            "ServerConsentNotAllowedRejection",
            __typename => {
              showToast({ variant: "error", title: translateError(__typename) });
            },
          )
          .with("CredentialNotUsableRejection", () => {
            showToast({
              variant: "error",
              title: t("toast.error.serverConsentCredentialAlreadyUsed"),
            });
          })
          .exhaustive();
      })
      .tapError((error: unknown) => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  const [formValues, setFormValues] = useState<Option<UpdateServerConsentProjectSettingsInput>>(
    Option.None,
  );

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder withTabs={false} />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      const settings = data?.serverConsentProjectSettings;
      const initialAllowedIpAddresses = settings?.allowedIpAddresses;

      const initialPublicKey =
        settings?.pendingConsent?.consentOperations
          .flat()
          .flatMap(item => item?.props ?? [])
          .find(item => item.name === "PublicKey")?.value ??
        settings?.serverConsentProjectCredential?.publicKey;

      return (
        <>
          {isNotNullish(data.project.ownerOnboardingId) && (
            <LakeModal
              visible={formValues.isSome()}
              title={t("consentNotification.title")}
              icon="phone-regular"
            >
              <ClientContext.Provider
                value={
                  shouldUseProjectMemberToken
                    ? livePartnerByProjectIdClient__projectMember(projectId)
                    : livePartnerByProjectIdClient(projectId)
                }
              >
                <ConfirmModal
                  onClose={() => setFormValues(Option.None)}
                  onboardingId={data.project.ownerOnboardingId}
                  loading={update.isLoading()}
                  onPressSave={() => {
                    formValues.match({
                      Some: values => onSubmit(values),
                      None: () => {},
                    });
                  }}
                />
              </ClientContext.Provider>
            </LakeModal>
          )}

          <ServerConsentForm
            onSubmit={values => setFormValues(Option.Some(values))}
            initialAllowedIpAddresses={initialAllowedIpAddresses}
            initialPublicKey={initialPublicKey}
            variant="Draft"
            disabled={
              isNotNullish(settings?.pendingConsentId) || isNullish(data.project.ownerOnboardingId)
            }
          />
        </>
      );
    })
    .exhaustive();
};
