import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { CountryPicker } from "@swan-io/shared-business/src/components/CountryPicker";
import { allCountries, CountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { useForm } from "@swan-io/use-form";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  SimulateIncomingCardAuthorizationCardCategory,
  SimulateIncomingCardAuthorizationCardScheme,
  SimulateIncomingCardAuthorizationCardType,
  SimulateIncomingCardAuthorizationDocument,
  SimulateIncomingCardAuthorizationStatus,
  SimulateIncomingCardAuthorizationThreeDsStatus,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

type FormApi = {
  paymentLinkId: string;
  scheme: SimulateIncomingCardAuthorizationCardScheme;
  category: SimulateIncomingCardAuthorizationCardCategory;
  type: SimulateIncomingCardAuthorizationCardType;
  country: CountryCCA3;
  autoCapture: boolean;
  authorizationStatus: SimulateIncomingCardAuthorizationStatus;
  authorizationRejectionReason: string;
  threeDsStatus: SimulateIncomingCardAuthorizationThreeDsStatus;
  threeDsRejectionReason: string;
};

const schemes = deriveUnion<SimulateIncomingCardAuthorizationCardScheme>({
  Visa: true,
  Mastercard: true,
  CartesBancaires: true,
}).array.map(value => ({ name: value, value }));

const categories = deriveUnion<SimulateIncomingCardAuthorizationCardCategory>({
  Commercial: true,
  Consumer: true,
}).array.map(value => ({ name: value, value }));

const types = deriveUnion<SimulateIncomingCardAuthorizationCardType>({
  Charge: true,
  Credit: true,
  Debit: true,
  DeferredDebit: true,
  Prepaid: true,
}).array.map(value => ({ name: value, value }));

const authorizationStatuses = deriveUnion<SimulateIncomingCardAuthorizationStatus>({
  Authorized: true,
  Rejected: true,
}).array.map(value => ({ name: value, value }));

const threeDsStatuses = deriveUnion<SimulateIncomingCardAuthorizationThreeDsStatus>({
  Successful: true,
  Failed: true,
}).array.map(value => ({ name: value, value }));

export const MerchantPaymentsCardsIncomingAuthorization = () => {
  const [mutate, simulation] = useMutation(SimulateIncomingCardAuthorizationDocument);

  const result = simulation
    .mapOk(simulation => simulation.response)
    .mapOkToResult(response =>
      match(response)
        .with({ __typename: "SimulateIncomingCardAuthorizationSuccessPayload" }, ({ paymentId }) =>
          Result.Ok([{ key: "paymentId", value: paymentId }]),
        )
        .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
    );

  const { Field, FieldsListener, formStatus, submitForm } = useForm<FormApi>({
    paymentLinkId: {
      initialValue: "",
      validate: validateRequired,
    },
    scheme: {
      initialValue: "Visa",
    },
    category: {
      initialValue: "Commercial",
    },
    type: {
      initialValue: "Credit",
    },
    country: {
      initialValue: "FRA",
    },
    autoCapture: {
      initialValue: true,
    },
    authorizationStatus: {
      initialValue: "Authorized",
    },
    authorizationRejectionReason: {
      initialValue: "",
    },
    threeDsStatus: {
      initialValue: "Successful",
    },
    threeDsRejectionReason: {
      initialValue: "",
    },
  });

  const onPressSubmit = () => {
    submitForm({
      onSuccess: ({ authorizationRejectionReason, threeDsRejectionReason, ...rest }) => {
        return Option.allFromDict(rest)
          .map(
            ({
              paymentLinkId,
              scheme,
              category,
              type,
              country,
              autoCapture,
              authorizationStatus,
              threeDsStatus,
            }) => {
              mutate({
                input: {
                  paymentLinkId,
                  cardDetails: {
                    scheme,
                    category,
                    type,
                    country,
                  },
                  autoCapture,
                  authorization: {
                    status: authorizationStatus,
                    rejectionReason: authorizationRejectionReason.toUndefined(),
                  },
                  threeDS: {
                    status: threeDsStatus,
                    rejectionReason: threeDsRejectionReason.toUndefined(),
                  },
                },
              });
            },
          )
          .toUndefined();
      },
    });
  };

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.merchantPayment.card.incomingAuthorization.description")}{" "}
            <DocumentationLink to="merchant">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <FieldsListener names={["authorizationStatus", "threeDsStatus"]}>
          {({ authorizationStatus, threeDsStatus }) => (
            <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
              <Field name="paymentLinkId">
                {({ value, valid, error, onChange, onBlur }) => (
                  <LakeLabel
                    label={`${t("simulatorForm.paymentLinkId")} *`}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        value={value}
                        placeholder={t("simulatorForm.paymentLinkIdPlaceholder")}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        valid={valid}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="scheme">
                {({ value, error, onChange }) => (
                  <LakeLabel
                    label={`${t("simulatorForm.cardScheme")} *`}
                    render={id => (
                      <LakeSelect
                        id={id}
                        value={value}
                        items={schemes}
                        onValueChange={onChange}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="category">
                {({ value, error, onChange }) => (
                  <LakeLabel
                    label={`${t("simulatorForm.cardCategory")} *`}
                    render={id => (
                      <LakeSelect
                        id={id}
                        value={value}
                        items={categories}
                        onValueChange={onChange}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="type">
                {({ value, error, onChange }) => (
                  <LakeLabel
                    label={`${t("simulatorForm.cardType")} *`}
                    render={id => (
                      <LakeSelect
                        id={id}
                        value={value}
                        items={types}
                        onValueChange={onChange}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="country">
                {({ value, onChange }) => (
                  <LakeLabel
                    label={`${t("simulatorForm.cardCountry")} *`}
                    render={id => (
                      <CountryPicker
                        id={id}
                        value={value}
                        onValueChange={onChange}
                        countries={allCountries}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="authorizationStatus">
                {({ value, error, onChange }) => (
                  <LakeLabel
                    label={`${t("simulatorForm.authorizationStatus")} *`}
                    render={id => (
                      <LakeSelect
                        id={id}
                        value={value}
                        items={authorizationStatuses}
                        onValueChange={onChange}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>

              {authorizationStatus.value === "Rejected" ? (
                <Field name="authorizationRejectionReason">
                  {({ value, valid, error, onChange, onBlur }) => (
                    <LakeLabel
                      label={`${t("simulatorForm.authorizationRejectionReason")} *`}
                      render={id => (
                        <LakeTextInput
                          id={id}
                          value={value}
                          onChangeText={onChange}
                          onBlur={onBlur}
                          valid={valid}
                          error={error}
                        />
                      )}
                    />
                  )}
                </Field>
              ) : null}

              <Field name="threeDsStatus">
                {({ value, error, onChange }) => (
                  <LakeLabel
                    label={`${t("simulatorForm.threeDsStatus")} *`}
                    render={id => (
                      <LakeSelect
                        id={id}
                        value={value}
                        items={threeDsStatuses}
                        onValueChange={onChange}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>

              {threeDsStatus.value === "Failed" ? (
                <Field name="threeDsRejectionReason">
                  {({ value, valid, error, onChange, onBlur }) => (
                    <LakeLabel
                      label={`${t("simulatorForm.threeDsRejectionReason")} *`}
                      render={id => (
                        <LakeTextInput
                          id={id}
                          value={value}
                          onChangeText={onChange}
                          onBlur={onBlur}
                          valid={valid}
                          error={error}
                        />
                      )}
                    />
                  )}
                </Field>
              ) : null}
            </Grid>
          )}
        </FieldsListener>

        <View style={styles.grid}>
          <Field name="autoCapture">
            {({ value, onChange }) => (
              <LakeLabelledCheckbox
                value={value}
                onValueChange={onChange}
                label={t("simulatorForm.autoCapture")}
              />
            )}
          </Field>
        </View>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit merchant payment card incoming authorization">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onPressSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "paymentId",
              label: t("simulatorForm.paymentId"),
              placeholder: "-",
            },
          ]}
          results={result}
        />
      </Box>
    </Form>
  );
};
