import { Avatar } from "@swan-io/lake/src/components/Avatar";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { PopoverContent } from "@swan-io/lake/src/components/PopoverContent";
import { PressableText } from "@swan-io/lake/src/components/Pressable";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { colors, spacings, texts } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { memo, useRef } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { LoggedUserInfoFragment } from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    alignItems: "center",
  },
  pressed: {
    opacity: 0.7,
  },
  popover: {
    padding: 0,
    paddingBottom: 12,
  },
  titleContainer: {
    paddingHorizontal: spacings[24],
    paddingVertical: spacings[12],
    borderBottomWidth: 1,
    borderBottomColor: colors.gray[100],
    alignItems: "center",
    flexDirection: "row",
  },
  horizontalPadding: {
    paddingHorizontal: 20,
  },
  signout: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userInfos: {
    flex: 1, // use this to make ellipsis working well for email
  },
  separator: {
    marginVertical: spacings[8],
  },
  item: {
    ...texts.regular,
    paddingVertical: spacings[8],
    paddingHorizontal: spacings[24],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  activeItem: {
    backgroundColor: colors.gray[50],
  },
  hoveredItem: {
    backgroundColor: colors.gray[50],
  },
});

type Props = {
  currentUser: LoggedUserInfoFragment;
};

export const ProfileButton = memo<Props>(({ currentUser }) => {
  const { projectId } = useProjectInfo();
  const route = Router.useRoute(["ProjectWithEnvArea", "ProjectAdminArea"]);

  const [isOpen, setIsOpen] = useDisclosure(false);
  const buttonRef = useRef<View>(null);

  const { identificationLevels } = currentUser;

  const hasValidIdentification =
    identificationLevels?.expert === true ||
    identificationLevels?.QES === true ||
    identificationLevels?.PVID === true;

  const signout = async () => {
    try {
      // FIXME: We call a REST endpoint for the moment (cf auth.controller.ts)
      await fetch("/auth/logout", {
        method: "POST",
        credentials: "include",
      });
    } catch (e) {
      console.error("Server side logout fails", e);
    } finally {
      setIsOpen.close();
      window.location.replace(Router.BaseLogin());
    }
  };

  const activeSection = match(route)
    .with({ name: "ProjectAdminArea" }, () => "admin" as const)
    .otherwise(() => "dashboard" as const);

  return (
    <>
      <Popover
        referenceRef={buttonRef}
        describedBy="Profile Information"
        onDismiss={setIsOpen.close}
        visible={isOpen}
      >
        <PopoverContent style={styles.popover}>
          <View style={styles.titleContainer}>
            <LakeText color={colors.gray[900]} variant="semibold">
              {t("profile.profile")}
            </LakeText>

            <Space width={12} />

            {hasValidIdentification ? (
              <Tag color="positive">{t("profile.verified")}</Tag>
            ) : (
              <Tag color="negative">{t("profile.unverified")}</Tag>
            )}
          </View>

          <Space height={8} />

          <PressableText
            style={({ hovered }) => [
              styles.item,
              hovered && styles.hoveredItem,
              activeSection === "admin" && styles.activeItem,
            ]}
            onPress={() => {
              Router.push("ProjectAdminTeam", { projectId });
              setIsOpen.close();
            }}
          >
            <Icon name="people-team-regular" size={16} color={colors.partner.primary} />
            <Space width={12} />
            <LakeText color={colors.gray[900]}>{t("profile.teamManagement")}</LakeText>
            <Fill minWidth={12} />

            {activeSection === "admin" && (
              <Icon color={colors.positive[500]} name="checkmark-filled" size={14} />
            )}
          </PressableText>

          <PressableText
            style={({ hovered }) => [
              styles.item,
              hovered && styles.hoveredItem,
              activeSection === "dashboard" && styles.activeItem,
            ]}
            onPress={() => {
              if (activeSection === "dashboard") {
                setIsOpen.close();
              } else {
                Router.push("OnboardingRoot", { projectId, projectEnv: "sandbox" });
                setIsOpen.close();
              }
            }}
          >
            <Icon name="board-regular" size={16} color={colors.partner.primary} />
            <Space width={12} />
            <LakeText color={colors.gray[900]}>{t("profile.dashboard")}</LakeText>
            <Fill minWidth={12} />

            {activeSection === "dashboard" && (
              <Icon color={colors.positive[500]} name="checkmark-filled" size={14} />
            )}
          </PressableText>

          <Separator style={styles.separator} />

          <TrackPressable action="Logout">
            <PressableText
              style={({ hovered }) => [styles.item, hovered && styles.hoveredItem]}
              onPress={() => void signout()}
            >
              <Icon name="sign-out-regular" size={16} color={colors.partner.primary} />
              <Space width={12} />
              <LakeText color={colors.gray[900]}>{t("profile.logout")}</LakeText>
            </PressableText>
          </TrackPressable>
        </PopoverContent>
      </Popover>

      <Pressable
        ref={buttonRef}
        style={({ pressed }) => [styles.button, pressed && styles.pressed]}
        onPress={setIsOpen.open}
      >
        <Avatar size={25} user={currentUser} />

        {isNotNullishOrEmpty(currentUser.fullName) && (
          <>
            <Space width={12} />

            <LakeText numberOfLines={1} color={colors.gray[800]} variant="semibold">
              {currentUser.fullName}
            </LakeText>
          </>
        )}

        <Space width={12} />
        <Icon name="chevron-down-filled" size={18} color={colors.gray[300]} />
      </Pressable>
    </>
  );
});
