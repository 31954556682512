import { Array, AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  BalanceCell,
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { deriveUnion, identity } from "@swan-io/lake/src/utils/function";
import {
  emptyToUndefined,
  isNotEmpty,
  isNotNullish,
  isNotNullishOrEmpty,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  FilterCheckboxDef,
  FilterDateDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { P, match } from "ts-pattern";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "../components/ColumnChooser";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  GetTransactionsDocument,
  OrderByDirection,
  PaymentProduct,
  TransactionListFragment,
  TransactionStatus,
  TransactionTypeEnum,
  TransactionsOrderByInput,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, formatCurrency, locale, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";
import { transactionTypes } from "../utils/transactions";
import {
  isAfterUpdatedAtSelectable,
  isBeforeUpdatedAtSelectable,
  validateAfterUpdatedAt,
  validateBeforeUpdatedAt,
} from "../utils/validations";

type Edge = GetEdge<TransactionListFragment>;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: TransactionsOrderByInput) => void;
  sortBy?: TransactionsOrderByInput;
  reexecuteQuery: () => void;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const defaultFixedColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 300,
    id: "account",
    title: t("transactions.account"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { account },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      if (isNotNullish(account)) {
        const accountId = account.id;

        return (
          <LinkCell
            onPress={() => Router.push("AccountDetailRoot", { projectId, projectEnv, accountId })}
          >
            {`${account.name} - ${account.number}`}
          </LinkCell>
        );
      }
      return null;
    },
  },
];

const defaultActiveColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 240,
    id: "accountHolder",
    title: t("transactions.accountHolder"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { account },
      },
      extraInfo: { projectId, projectEnv },
    }) => {
      if (isNotNullish(account)) {
        const accountHolderId = account.holder.id;

        return (
          <LinkCell
            onPress={() =>
              Router.push("HoldersDetailRoot", {
                projectId,
                projectEnv,
                accountHolderId,
              })
            }
          >
            {account.holder.info.name}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 200,
    id: "executionDate",
    title: t("transactions.executionDate"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transations by execution date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "executionDate", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "executionDate"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { executionDate },
      },
    }) => (
      <TextCell text={dayjs(executionDate).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    width: 130,
    id: "status",
    title: t("transactions.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { statusInfo },
      },
    }) => (
      <Cell>
        {match(statusInfo.status)
          .with("Booked", () => <Tag color="positive">{t("transactions.status.booked")}</Tag>)
          .with("Canceled", () => <Tag color="gray">{t("transactions.status.canceled")}</Tag>)
          .with("Pending", () => <Tag color="shakespear">{t("transactions.status.pending")}</Tag>)
          .with("Rejected", () => <Tag color="negative">{t("transactions.status.rejected")}</Tag>)
          .with("Released", () => <Tag color="gray">{t("transactions.status.released")}</Tag>)
          .with("Upcoming", () => <Tag color="shakespear">{t("transactions.status.upcoming")}</Tag>)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 150,
    id: "amount",
    title: t("transaction.amount"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => {
      const {
        amount: { currency, value },
        side,
      } = node;

      const originalAmount = match(node)
        .with({ __typename: "CardTransaction" }, ({ amount: { value, currency } }) => {
          const unsigned = Number(value);

          return {
            value:
              unsigned *
              (unsigned === 0
                ? 1
                : match(side)
                    .with("Debit", () => -1)
                    .with("Credit", () => 1)
                    .exhaustive()),
            currency,
          };
        })
        .otherwise(() => undefined);

      const unsigned = Number(value);

      return (
        <BalanceCell
          currency={currency}
          value={
            unsigned *
            (unsigned === 0
              ? 1
              : match(side)
                  .with("Debit", () => -1)
                  .with("Credit", () => 1)
                  .exhaustive())
          }
          originalValue={originalAmount}
          formatCurrency={formatCurrency}
        />
      );
    },
  },
  {
    width: 240,
    id: "label",
    title: t("transactions.label"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { label },
      },
    }) => <TextCell text={label} />,
  },
  {
    width: 300,
    id: "id",
    title: t("transactions.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 240,
    id: "paymentProduct",
    title: t("transactions.paymentProduct"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentProduct },
      },
    }) => (
      <Cell>
        {match(paymentProduct)
          .with("Card", () => <Tag color="gray">{t("transactions.paymentProduct.card")}</Tag>)
          .with("Check", () => <Tag color="gray">{t("transactions.paymentProduct.check")}</Tag>)
          .with("Fees", () => <Tag color="gray">{t("transactions.paymentProduct.fees")}</Tag>)
          .with("InternalCreditTransfer", () => (
            <Tag color="gray">{t("transactions.paymentProduct.internalCreditTransfer")}</Tag>
          ))
          .with("InternalDirectDebit", () => (
            <Tag color="gray">{t("transactions.paymentProduct.internalDirectDebit")}</Tag>
          ))
          .with("InternationalCreditTransfer", () => (
            <Tag color="gray">{t("transactions.paymentProduct.internationalCreditTransfer")}</Tag>
          ))
          .with("SEPACreditTransfer", () => (
            <Tag color="gray">{t("transactions.paymentProduct.SEPACreditTransfer")}</Tag>
          ))
          .with("SEPADirectDebit", () => (
            <Tag color="gray">{t("transactions.paymentProduct.SEPADirectDebit")}</Tag>
          ))
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 290,
    id: "type",
    title: t("transactions.type"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { type },
      },
    }) => (
      <Cell>
        <Tag color="gray">{type}</Tag>
      </Cell>
    ),
  },
  {
    width: 350,
    id: "paymentMethod",
    title: t("transactions.paymentMethod"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentMethodIdentifier },
      },
    }) => (
      <CopyableTextCell
        text={paymentMethodIdentifier}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 240,
    id: "counterParty",
    title: t("transactions.counterParty"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { counterparty },
      },
    }) => <TextCell text={counterparty} />,
  },
  {
    width: 240,
    id: "createdAt",
    title: t("transactions.createdAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transations by creation date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 240,
    id: "updatedAt",
    title: t("transactions.updatedAt"),
    renderTitle: ({ title, extraInfo }) => (
      <TrackPressable action="Sort transations by update date">
        <HeaderCell
          text={title}
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) => <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 240,
    id: "reference",
    title: t("transactions.reference"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { reference },
      },
    }) => <TextCell text={isNotEmpty(reference) ? reference : "-"} />,
  },
  {
    width: 240,
    id: "externalReference",
    title: t("transactions.externalReference"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { externalReference },
      },
    }) => <TextCell text={isNotNullishOrEmpty(externalReference) ? externalReference : "-"} />,
  },
  {
    width: 240,
    id: "bookedBalanceAfter",
    title: t("transactions.bookedBalanceAfter"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { bookedBalanceAfter },
      },
    }) => (
      <TextCell
        text={
          isNotNullish(bookedBalanceAfter) && bookedBalanceAfter.value !== ""
            ? formatCurrency(Number(bookedBalanceAfter.value), bookedBalanceAfter.currency)
            : "-"
        }
      />
    ),
  },
];

type Props = {
  params: RouteParams<"TransactionsList">;
};

type TransactionsFilters = FiltersState<typeof filtersDefinition>;

const PER_PAGE = 20;

const paymentProducts = deriveUnion<PaymentProduct>({
  Card: true,
  Check: true,
  InternalCreditTransfer: true,
  InternationalCreditTransfer: true,
  InternalDirectDebit: true,
  SEPACreditTransfer: true,
  SEPADirectDebit: true,
  Fees: true,
});

const isAfterUpdatedAtFilter: FilterDateDef = {
  type: "date",
  label: t("transaction.filters.isAfterUpdatedAt.label"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  isSelectable: isAfterUpdatedAtSelectable,
  validate: validateAfterUpdatedAt,
};

const isBeforeUpdatedAtFilter: FilterDateDef = {
  type: "date",
  label: t("transaction.filters.isBeforeUpdatedAt.label"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  isSelectable: isBeforeUpdatedAtSelectable,
  validate: validateBeforeUpdatedAt,
};

const paymentProductFilter: FilterCheckboxDef<PaymentProduct> = {
  type: "checkbox",
  label: t("transaction.filters.paymentProduct.label"),
  checkAllLabel: t("common.filters.all"),
  items: paymentProducts.array.map(value => ({ label: value, value })),
};

const statuses = ["Booked", "Canceled", "Pending", "Rejected", "Released", "Upcoming"] as const;

const statusFilter: FilterCheckboxDef<TransactionStatus> = {
  type: "checkbox",
  label: t("transaction.filters.status.label"),
  checkAllLabel: t("common.filters.all"),
  items: statuses.map(value => ({ label: value, value })),
};

const typeFilter: FilterCheckboxDef<TransactionTypeEnum> = {
  type: "checkbox",
  label: t("transaction.filters.type.label"),
  checkAllLabel: t("common.filters.all"),
  items: transactionTypes.array.map(value => ({ label: value, value })),
};

const filtersDefinition = {
  isAfterUpdatedAt: isAfterUpdatedAtFilter,
  isBeforeUpdatedAt: isBeforeUpdatedAtFilter,
  paymentProduct: paymentProductFilter,
  status: statusFilter,
  type: typeFilter,
};

type FiltersFormProps = {
  filters: TransactionsFilters;
  columns: ColumnChooserConfig<Edge, ExtraInfo>;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: TransactionsFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

const FiltersForm = ({
  filters,
  columns,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: FiltersFormProps) => {
  const availableFilters: { name: keyof TransactionsFilters; label: string }[] = useMemo(
    () => [
      {
        name: "isAfterUpdatedAt",
        label: t("transaction.filters.isAfterUpdatedAt.label"),
      },
      {
        name: "isBeforeUpdatedAt",
        label: t("transaction.filters.isBeforeUpdatedAt.label"),
      },
      {
        name: "paymentProduct",
        label: t("transaction.filters.paymentProduct.label"),
      },
      {
        name: "status",
        label: t("transaction.filters.status.label"),
      },
      {
        name: "type",
        label: t("transaction.filters.type.label"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);

      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <View>
      <Box direction="row" alignItems="center">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <TrackPressable action="Refresh transactions list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            loading={isRefreshing}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.match({
              Some: count => (
                <Tag color="partner">
                  {count === 10000 ? `${formatCount(count)}+` : formatCount(count)}
                </Tag>
              ),
              None: () => null,
            })
          }
        />
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </View>
  );
};

export const TransactionsListPage = ({ params }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();

  // The following pattern matching is used to correctly type the values,
  // incorrect values are set as `undefined` (aka. unset).
  const serializedPaymentProduct = JSON.stringify(params.paymentProduct ?? null);
  const serializedStatus = JSON.stringify(params.status ?? null);
  const serializedType = JSON.stringify(params.type ?? null);

  const filters: TransactionsFilters = useMemo(() => {
    const paymentProduct = JSON.parse(serializedPaymentProduct) as string[] | null | undefined;
    const status = JSON.parse(serializedStatus) as string[] | null | undefined;
    const type = JSON.parse(serializedType) as string[] | null | undefined;

    return {
      isAfterUpdatedAt: params.isAfterUpdatedAt,
      isBeforeUpdatedAt: params.isBeforeUpdatedAt,
      paymentProduct: isNotNullish(paymentProduct)
        ? Array.filterMap(paymentProduct, item =>
            match(item)
              .with(paymentProducts.P, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      status: isNotNullish(status)
        ? Array.filterMap(status, item =>
            match(item)
              .with(...statuses, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,

      type: isNotNullish(type)
        ? Array.filterMap(type, item =>
            transactionTypes.is(item) ? Option.Some(item) : Option.None(),
          )
        : undefined,
    } as const;
  }, [
    params.isAfterUpdatedAt,
    params.isBeforeUpdatedAt,
    serializedPaymentProduct,
    serializedStatus,
    serializedType,
  ]);

  const sortBy: TransactionsOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<TransactionsOrderByInput["field"]>()
        .with("createdAt", "updatedAt", "executionDate", identity)
        .otherwise(() => "executionDate"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search);
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetTransactionsDocument, {
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const columns = useColumnChooser("Transactions", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("TransactionsList", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, sortBy, reload, params]);

  const getRowLink = useCallback(
    ({
      item: {
        node: { id, account },
      },
      extraInfo: { projectEnv, projectId },
    }: LinkConfig<GetEdge<TransactionListFragment>, ExtraInfo>) =>
      account?.id == null ? undefined : (
        <Link
          to={Router.TransactionsDetailRoot({
            projectId,
            projectEnv,
            transactionId: id,
          })}
        />
      ),
    [],
  );

  const transactions = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ transactions }) => transactions);

  const totalCount = transactions.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <FiltersForm
        filters={filters}
        columns={columns}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("TransactionsList", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("TransactionsList", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.transactions}>
            {transactions => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                extraInfo={extraInfo}
                keyExtractor={keyExtractor}
                data={transactions.edges}
                stickedToStartColumns={columns.fixed}
                columns={columns.active}
                onEndReached={() => {
                  if (transactions.pageInfo.hasNextPage === true) {
                    setVariables({ after: transactions.pageInfo.endCursor ?? undefined });
                  }
                }}
                headerHeight={48}
                rowHeight={48}
                getRowLink={getRowLink}
                loading={{
                  isLoading,
                  count: PER_PAGE,
                }}
                renderEmptyList={() =>
                  hasSearchOrFilters ? (
                    <EmptyView
                      icon="clipboard-search-regular"
                      title={t("common.list.noResults")}
                      subtitle={t("common.list.noResultsSuggestion")}
                    />
                  ) : (
                    <EmptyView icon="lake-inbox-empty" title={t("transactions.empty")} />
                  )
                }
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
