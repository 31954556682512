import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { badStatusToError, Request } from "@swan-io/request";
import { FilesUploader } from "@swan-io/shared-business/src/components/FilesUploader";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { SwanFileStatusInfo } from "@swan-io/shared-business/src/utils/SwanFile";
import dayjs from "dayjs";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import {
  CapitalDepositDocumentFragment,
  CapitalDepositDocumentStatus,
  CapitalDepositDocumentType,
  GenerateCapitalDepositDocumentUploadUrlDocument,
} from "../graphql/partner";
import { locale, t } from "../utils/i18n";
import { getCapitalDepositDocumentRejectionReason } from "../utils/templateTranslations";

const styles = StyleSheet.create({
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  column: {
    flexBasis: "50%",
  },
});

const ACCEPTED_FORMATS = ["application/pdf", "image/png", "image/jpeg"];

type Props = { document: CapitalDepositDocumentFragment; capitalDepositCaseId: string };

const uploadableStatuses: Set<CapitalDepositDocumentStatus> = new Set([
  "Pending",
  "Uploaded",
  "Refused",
]);

const readonlyDocumentTypes: Set<CapitalDepositDocumentType> = new Set([
  "CapitalShareDepositCertificate",
]);

export const CapitalDepositDocumentTile = ({ document, capitalDepositCaseId }: Props) => {
  const [generateCapitalDepositDocument] = useMutation(
    GenerateCapitalDepositDocumentUploadUrlDocument,
  );

  const statusInfo = match(document.statusInfo)
    .returnType<Option<SwanFileStatusInfo>>()
    .with({ __typename: "CapitalDepositDocumentPendingStatusInfo" }, () => Option.None())
    .with({ __typename: "CapitalDepositDocumentRefusedStatusInfo" }, ({ reasonCode }) =>
      Option.Some({
        status: "Refused",
        reason: getCapitalDepositDocumentRejectionReason(reasonCode),
      }),
    )
    .with({ __typename: "CapitalDepositDocumentUploadedStatusInfo" }, () =>
      Option.Some({
        status: "Uploaded",
      }),
    )
    .with({ __typename: "CapitalDepositDocumentValidatedStatusInfo" }, () =>
      Option.Some({
        status: "Validated",
      }),
    )
    .exhaustive();

  return (
    <Tile>
      <Box direction="row" alignItems="center">
        <LakeHeading level={2} variant="h3">
          {match(document.type)
            .with("ArticlesOfIncorporation", () =>
              t("capitalDeposit.documents.type.ArticlesOfIncorporation"),
            )
            .with("CapitalShareDepositCertificate", () =>
              t("capitalDeposit.documents.type.CapitalShareDepositCertificate"),
            )
            .with("CompanyLeaseAgreement", () =>
              t("capitalDeposit.documents.type.CompanyLeaseAgreement"),
            )
            .with("CorporateIncomeTaxReturn", () =>
              t("capitalDeposit.documents.type.CorporateIncomeTaxReturn"),
            )
            .with("PowerOfAttorney", () => t("capitalDeposit.documents.type.PowerOfAttorney"))
            .with("ProofOfIdentity", () => t("capitalDeposit.documents.type.ProofOfIdentity"))
            .with("ProofOfIndividualAddress", () =>
              t("capitalDeposit.documents.type.ProofOfIndividualAddress"),
            )
            .with("RegisterExtract", () => t("capitalDeposit.documents.type.RegisterExtract"))
            .exhaustive()}
        </LakeHeading>

        <Space width={8} />

        {match(document.statusInfo.status)
          .with("Pending", () =>
            readonlyDocumentTypes.has(document.type) ? null : (
              <Tag color="shakespear">{t("capitalDeposit.document.status.pending")}</Tag>
            ),
          )
          .with("Refused", () => (
            <Tag color="negative">{t("capitalDeposit.document.status.refused")}</Tag>
          ))
          .with("Uploaded", () => (
            <Tag color="warning">{t("capitalDeposit.document.status.uploaded")}</Tag>
          ))
          .with("Validated", () => (
            <Tag color="positive">{t("capitalDeposit.document.status.validated")}</Tag>
          ))
          .exhaustive()}
      </Box>

      <Space height={24} />

      <Box direction="row" alignItems="start">
        <View style={styles.column}>
          <LakeLabel
            type="viewSmall"
            label={t("capitalDeposit.documents.id")}
            render={() => (
              <LakeText style={styles.text} color={colors.gray[900]}>
                {document.id}
              </LakeText>
            )}
            actions={
              <LakeCopyButton
                valueToCopy={document.id}
                copyText={t("copyButton.copyTooltip")}
                copiedText={t("copyButton.copiedTooltip")}
              />
            }
          />

          <Separator horizontal={false} space={8} />

          <LakeLabel
            type="viewSmall"
            label={t("capitalDeposit.documents.uploadedAt")}
            render={() =>
              document.uploadedAt != null ? (
                <LakeText style={styles.text} color={colors.gray[900]}>
                  {dayjs(document.uploadedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                </LakeText>
              ) : (
                <LakeText>-</LakeText>
              )
            }
          />
        </View>

        <Space width={12} />

        <View style={styles.column}>
          <LakeLabel
            type="viewSmall"
            label={t("capitalDeposit.documents.createdAt")}
            render={() => (
              <LakeText style={styles.text} color={colors.gray[900]}>
                {dayjs(document.createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
              </LakeText>
            )}
          />

          <Separator horizontal={false} space={8} />

          <LakeLabel
            type="viewSmall"
            label={t("capitalDeposit.documents.updatedAt")}
            render={() => (
              <LakeText style={styles.text} color={colors.gray[900]}>
                {dayjs(document.updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
              </LakeText>
            )}
          />
        </View>
      </Box>

      <Space height={24} />

      <FilesUploader
        // Only allow uploading is the Supporting Document Collection awaits for docs
        // and that the specific purpose isn't already fully validated
        canUpload={
          uploadableStatuses.has(document.statusInfo.status) &&
          !readonlyDocumentTypes.has(document.type)
        }
        accept={ACCEPTED_FORMATS}
        maxSize={20_000_000}
        icon="document-regular"
        initialFiles={statusInfo
          .map(statusInfo => [
            {
              id: document.id,
              name: t("common.document"),
              url: document.downloadUrl ?? "",
              statusInfo,
            },
          ])
          .getOr([])}
        generateUpload={({ fileName }: { fileName: string }) => {
          return generateCapitalDepositDocument({
            input: {
              filename: fileName,
              capitalDepositCaseId,
              documentId: document.id,
            },
          })
            .mapOk(data => data.generateCapitalDepositDocumentUploadUrl)
            .mapOkToResult(input =>
              match(input)
                .with({ __typename: "CapitalDepositDocumentCanNotBeUploaded" }, () =>
                  Result.Error(input),
                )
                .otherwise(() => filterRejectionsToResult(input)),
            )
            .mapOk(input =>
              match(input)
                .with(
                  { __typename: "GenerateCapitalDepositDocumentUploadUrlSuccessPayload" },
                  ({ uploadUrl }) => ({
                    upload: { url: uploadUrl ?? "", fields: [] },
                    id: document.id,
                  }),
                )
                .otherwise(() => ({
                  upload: { url: "", fields: [] },
                  id: document.id,
                })),
            )
            .mapError(error =>
              showToast({
                variant: "error",
                error,
                title: translateError(error),
              }),
            );
        }}
        getUploadConfig={file => ({ fileName: file.name })}
        uploadFile={({ upload, file }) =>
          Request.make({
            url: upload.url,
            method: "PUT",
            headers: {
              "content-type": "multipart/form-data",
            },
            body: file,
            type: "text",
          })
            .mapOkToResult(badStatusToError)
            .tapOk(() =>
              showToast({
                variant: "success",
                title: t("capitalDeposit.documentUploaded"),
              }),
            )
            .tapError(error => {
              showToast({
                variant: "error",
                error,
                title: translateError(error),
              });
            })
        }
        formatAndSizeDescription={t("capitalDeposit.document.supported", {
          maxSizeMB: 20_000_000 / 1_000_000,
        })}
      />
    </Tile>
  );
};
