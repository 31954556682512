import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullishOrEmpty, isNullish } from "@swan-io/lake/src/utils/nullish";
import { CSSProperties, useCallback } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { Image, Pressable, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { t } from "../utils/i18n";

const convertFileToCanvas = (file: File): Promise<HTMLCanvasElement> =>
  new Promise((resolve, reject) => {
    const img = document.createElement("img");
    const url = URL.createObjectURL(file);

    img.src = url;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        return reject(new Error("Unable to create canvas context"));
      }

      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      resolve(canvas);
    };
  });

// based on https://ourcodeworld.com/articles/read/683/how-to-remove-the-transparent-pixels-that-surrounds-a-canvas-in-javascript
const trimCanvasWhiteSpace = async (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    const error = new Error("Unable to create canvas context");
    return Promise.reject(error);
  }

  const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const bound = { left: 0, right: 0, top: 0, bottom: 0 };

  for (let i = 0; i < pixels.data.length; i += 4) {
    if (pixels.data[i + 3] === 0) {
      continue;
    }

    const x = (i / 4) % canvas.width;
    const y = ~~(i / 4 / canvas.width);

    if (bound.left === 0 || x < bound.left) {
      bound.left = x;
    }
    if (bound.right === 0 || bound.right < x) {
      bound.right = x;
    }
    if (bound.top === 0) {
      bound.top = y;
    }
    if (bound.bottom === 0 || bound.bottom < y) {
      bound.bottom = y;
    }
  }

  const copy = document.createElement("canvas");
  const copyCtx = copy.getContext("2d");

  if (!copyCtx) {
    throw new Error("Unable to create canvas context");
  }

  copy.height = bound.bottom - bound.top;
  copy.width = bound.right - bound.left;
  copyCtx.putImageData(ctx.getImageData(bound.left, bound.top, copy.width, copy.height), 0, 0);

  return Promise.resolve(copy);
};

const resizeCanvas = (canvas: HTMLCanvasElement, height: number): Promise<HTMLCanvasElement> => {
  // Avoid rounding issues by allowing 2px less
  if (canvas.height < height - 2) {
    throw new Error("Image doesn't respect required height");
  }

  const copy = document.createElement("canvas");
  const ctx = copy.getContext("2d");

  copy.height = height;
  copy.width = Math.ceil((canvas.width / canvas.height) * height);

  if (!ctx) {
    const error = new Error("Unable to create canvas context");
    return Promise.reject(error);
  }

  ctx.drawImage(canvas, 0, 0, copy.width, copy.height);
  return Promise.resolve(copy);
};

// TODO handle error (display a toast)
const getBase64Uri = (file: File, fixedHeight: number) =>
  convertFileToCanvas(file)
    .then(canvas => trimCanvasWhiteSpace(canvas))
    .then(canvas => resizeCanvas(canvas, fixedHeight))
    .then(canvas => canvas.toDataURL());

const styles = StyleSheet.create({
  text: {
    marginBottom: 2,
    marginLeft: 2,
  },
  container: {
    borderStyle: "dashed",
    cursor: "pointer",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.gray[100],
    height: 100,
  },
  active: {
    borderColor: colors.gray[200],
  },
  openButton: {
    alignSelf: "flex-start",
  },
  previewLogo: {
    maxHeight: 48,
    maxWidth: "90%",
    height: 48,
    width: "90%",
  },
  disabled: {
    backgroundColor: colors.gray[50],
    cursor: "not-allowed",
  },
  error: {
    borderColor: colors.negative[500],
  },
  deleteButton: {
    position: "absolute",
    right: 16,
    top: 16,
  },
  deleteButtonHovered: {
    opacity: 0.8,
  },
});

const inlineStyle: CSSProperties = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

type Props = {
  disabled?: boolean;
  error?: string;
  minHeight: number;
  acceptedImageUpload: string;
  imageUri: string;
  onImageChange: (base64Uri: string) => void;
  onImageDelete?: () => void;
  onImageLoadError: () => void;
  onImageRejected: () => void;
  buttonText: string;
  style?: StyleProp<ViewStyle>;
  accept?: string[];
};

export const ImageUploadInput = ({
  disabled = false,
  error,
  minHeight,
  acceptedImageUpload,
  imageUri,
  onImageChange,
  onImageDelete,
  onImageLoadError,
  onImageRejected,
  buttonText,
  style,
  accept = ["image/png"],
}: Props) => {
  const onDropAccepted = useCallback(
    (files: File[]) => {
      const file = files[0];

      if (isNullish(file)) {
        return;
      }

      getBase64Uri(file, minHeight)
        .then(onImageChange)
        .catch(() => onImageRejected());
    },
    [minHeight, onImageChange, onImageRejected],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: accept.reduce<Accept>((acc, item) => {
      acc[item] = [];
      return acc;
    }, {}),
    onDropAccepted,
    onDropRejected: onImageRejected,
    disabled,
  });

  const hasError = isNotNullishOrEmpty(error);

  return (
    <View style={style}>
      <View
        style={[
          styles.container,
          disabled ? styles.disabled : isDragActive ? styles.active : false,
          hasError ? styles.error : isDragActive ? styles.active : false,
        ]}
      >
        <div {...getRootProps()} style={inlineStyle}>
          <input {...getInputProps()} />

          {imageUri ? (
            <>
              {!disabled && !isNullish(onImageDelete) && (
                <Pressable
                  role="button"
                  onPress={onImageDelete}
                  style={({ hovered }) => [
                    styles.deleteButton,
                    hovered && styles.deleteButtonHovered,
                  ]}
                >
                  <Icon color={colors.negative[500]} name="delete-regular" size={20} />
                </Pressable>
              )}

              <Image
                aria-label={t("phonePreview.logoAlt")}
                source={{ uri: imageUri }}
                onError={onImageLoadError}
                resizeMode="contain"
                style={styles.previewLogo}
              />
            </>
          ) : (
            <Box direction="row" alignItems="center">
              <Icon
                color={hasError ? colors.negative[500] : colors.gray[300]}
                name={!disabled && isDragActive ? "image-add-filled" : "image-add-regular"}
                size={20}
              />

              <Space width={12} />

              <LakeText color={hasError ? colors.negative[500] : undefined}>
                {t("imageUploadInput.placeholder")}
              </LakeText>
            </Box>
          )}
        </div>
      </View>

      <Space height={20} />

      {!disabled && (
        <>
          <LakeButton
            style={styles.openButton}
            mode="secondary"
            size="small"
            icon="add-filled"
            disabled={disabled}
            onPress={open}
          >
            {buttonText}
          </LakeButton>

          <Space height={8} />
          <LakeText style={styles.text}>{acceptedImageUpload}</LakeText>
        </>
      )}
    </View>
  );
};
