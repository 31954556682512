import { Link } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import dayjs from "dayjs";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { GetCapitalDepositQuery } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { CapitalDepositDocuments } from "./CapitalDepositDocuments";

type Props = {
  capitalDeposit: NonNullable<GetCapitalDepositQuery["capitalDepositCase"]>;
};

const styles = StyleSheet.create({
  unknownValue: {
    fontStyle: "italic",
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

export const CapitalDepositDetailGeneral = ({ capitalDeposit }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const hasShareholderWithoutCapitalTransferredStatus = capitalDeposit.shareholders.some(
    ({ status }) => status !== "CapitalTransferred",
  );

  const documentsWithPendingStatus = capitalDeposit.documents.filter(
    ({ statusInfo: { status } }) => status === "Pending",
  ).length;

  const messagesIfWaitingForRequirements: string[] = [];
  if (hasShareholderWithoutCapitalTransferredStatus) {
    messagesIfWaitingForRequirements.push(
      t("capitalDeposit.alert.shareholdersMustFinalizeOnboarding"),
    );
  }
  if (isNullish(capitalDeposit.companyAccountId)) {
    messagesIfWaitingForRequirements.push(t("capitalDeposit.alert.onboardingToFinalize"));
  }
  if (documentsWithPendingStatus > 2) {
    messagesIfWaitingForRequirements.push(t("capitalDeposit.alert.pendingStatus"));
  }

  return (
    <>
      {match(capitalDeposit)
        .with({ status: "WaitingForRequirements" }, () => {
          return (
            <>
              <LakeAlert title={t("capitalDeposit.alert.waitingForRequirement")} variant="warning">
                <Box>
                  <LakeText color={colors.gray[500]} variant="regular">
                    {messagesIfWaitingForRequirements.join("\n")}
                  </LakeText>
                </Box>
              </LakeAlert>

              <Space height={24} />
            </>
          );
        })
        .with({ status: "WaitingForRegisterExtract" }, () => (
          <>
            <LakeAlert
              title={t("capitalDeposit.alert.waitingForRegisterExtract")}
              variant="warning"
            />

            <Space height={24} />
          </>
        ))
        .with({ status: "WaitingForShareDepositCertificate" }, () => (
          <>
            <LakeAlert
              title={t("capitalDeposit.alert.waitingForShareDepositCertificate")}
              variant="info"
            />

            <Space height={24} />
          </>
        ))
        .with({ status: "WaitingForRegisterExtract" }, () => (
          <>
            <LakeAlert title={t("capitalDeposit.alert.waitingForRegisterExtract")} variant="info" />
            <Space height={24} />
          </>
        ))
        .with({ status: "WaitingForNotaryTransfer" }, () => (
          <>
            <LakeAlert title={t("capitalDeposit.alert.waitingForNotaryTransfer")} variant="info" />
            <Space height={24} />
          </>
        ))
        .with({ status: "Completed" }, () => (
          <>
            <LakeAlert title={t("capitalDeposit.alert.completed")} variant="success" />
            <Space height={24} />
          </>
        ))
        .otherwise(() => null)}

      <TileGrid>
        <>
          <LakeHeading variant="h2" level={2}>
            {t("capitalDeposit.capitalDeposit")}
          </LakeHeading>

          <Space height={24} />

          <TileGrid>
            <Tile title={t("capitalDeposit.capitalDeposit")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  label={t("capitalDeposit.companyName")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>{capitalDeposit.companyName}</LakeText>
                  )}
                />

                <LakeLabel
                  label={t("capitalDeposit.status")}
                  type="view"
                  color="current"
                  render={() =>
                    match(capitalDeposit.status)
                      .with("Canceled", () => (
                        <Tag color="positive">{t("capitalDeposit.status.canceled")}</Tag>
                      ))
                      .with("Completed", () => (
                        <Tag color="positive">{t("capitalDeposit.status.completed")}</Tag>
                      ))
                      .with("WaitingForRequirements", () => (
                        <Tag color="gray">{t("capitalDeposit.status.waitingForRequirements")}</Tag>
                      ))
                      .with("Initiated", () => (
                        <Tag color="negative">{t("capitalDeposit.status.initiated")}</Tag>
                      ))
                      .with("WaitingForShareDepositCertificate", () => (
                        <Tag color="warning">
                          {t("capitalDeposit.status.waitingForShareDepositCertificate")}
                        </Tag>
                      ))
                      .with("WaitingForRegisterExtract", () => (
                        <Tag color="shakespear">
                          {t("capitalDeposit.status.waitingForRegisterExtract")}
                        </Tag>
                      ))
                      .with("WaitingForNotaryTransfer", () => (
                        <Tag color="warning">
                          {t("capitalDeposit.status.waitingForNotaryTransfer")}
                        </Tag>
                      ))
                      .exhaustive()
                  }
                />

                <LakeLabel
                  label={t("capitalDeposit.id")}
                  type="view"
                  color="current"
                  render={() => <LakeText color={colors.gray[900]}>{capitalDeposit.id}</LakeText>}
                  actions={
                    <LakeCopyButton
                      valueToCopy={capitalDeposit.id}
                      copyText={t("copyButton.copyTooltip")}
                      copiedText={t("copyButton.copiedTooltip")}
                    />
                  }
                />

                <LakeLabel
                  label={t("capitalDeposit.companyAccountId")}
                  type="view"
                  color="current"
                  actions={
                    isNotNullish(capitalDeposit.companyAccountId) && (
                      <Link
                        to={Router.AccountDetailRoot({
                          projectId,
                          projectEnv,
                          accountId: capitalDeposit.companyAccountId,
                        })}
                      >
                        <Icon size={20} name="arrow-right-filled" color={colors.swan[900]} />
                      </Link>
                    )
                  }
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {capitalDeposit.companyAccountId ?? UNKNOWN_VALUE}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  label={t("capitalDeposit.amount")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {capitalDeposit.totalCapitalDepositAmount.value !== ""
                        ? formatCurrency(
                            Number(capitalDeposit.totalCapitalDepositAmount.value),
                            capitalDeposit.totalCapitalDepositAmount.currency,
                          )
                        : UNKNOWN_VALUE}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  label={t("capitalDeposit.createdAt")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(capitalDeposit.createdAt).format(
                        `${locale.dateFormat} ${locale.timeFormat}`,
                      )}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  label={t("capitalDeposit.updatedAt")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(capitalDeposit.updatedAt).format(
                        `${locale.dateFormat} ${locale.timeFormat}`,
                      )}
                    </LakeText>
                  )}
                />
              </ReadOnlyFieldList>
            </Tile>

            <Tile title={t("capitalDeposit.companyOnboarding")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  label={t("capitalDeposit.companyOnboardingId")}
                  type="view"
                  color="current"
                  actions={
                    isNotNullish(capitalDeposit.companyOnboarding) ? (
                      <Link
                        to={Router.OnboardingDetailRoot({
                          projectId,
                          projectEnv,
                          onboardingId: capitalDeposit.companyOnboarding.id,
                        })}
                      >
                        <Icon size={20} name="arrow-right-filled" color={colors.swan[900]} />
                      </Link>
                    ) : (
                      UNKNOWN_VALUE
                    )
                  }
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {capitalDeposit.companyOnboarding?.id}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  label={t("capitalDeposit.status")}
                  type="view"
                  color="current"
                  render={() =>
                    match(capitalDeposit.companyOnboarding?.statusInfo)
                      .with({ __typename: "OnboardingFinalizedStatusInfo" }, () => (
                        <Tag color="positive">
                          {t("capitalDeposit.companyOnboarding.completed")}
                        </Tag>
                      ))
                      .with(
                        { __typename: "OnboardingInvalidStatusInfo" },
                        { __typename: "OnboardingValidStatusInfo" },
                        () => (
                          <Tag color="warning">{t("capitalDeposit.companyOnboarding.ongoing")}</Tag>
                        ),
                      )
                      .otherwise(() => null)
                  }
                />
              </ReadOnlyFieldList>
            </Tile>
          </TileGrid>
        </>

        <>
          <LakeHeading variant="h2" level={2}>
            {t("capitalDeposit.documents")}
          </LakeHeading>

          <Space height={24} />

          <CapitalDepositDocuments
            capitalDepositCaseId={capitalDeposit.id}
            documents={capitalDeposit.documents}
          />
        </>
      </TileGrid>
    </>
  );
};
