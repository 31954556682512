import { Option } from "@swan-io/boxed";
import { encodeSearch, pushUnsafe, useLocation } from "@swan-io/chicane";
import { ClientContext } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { SwanLogo } from "@swan-io/lake/src/components/SwanLogo";
import { backgroundColor, colors, spacings, texts } from "@swan-io/lake/src/constants/design";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { useCallback } from "react";
import { atom, useAtomWithSelector } from "react-atomic-state";
import { StyleSheet, Text, View } from "react-native";
import { match } from "ts-pattern";
import { LoggedUserInfoFragment } from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import {
  livePartnerByProjectIdClient,
  livePartnerByProjectIdClient__projectMember,
  sandboxPartnerByProjectIdClient,
  sandboxPartnerByProjectIdClient__projectMember,
} from "../utils/gql";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../utils/storage";
import { useTgglFlag } from "../utils/tggl";
import { GlobalSearch } from "./GlobalSearch";
import { ProfileButton } from "./ProfileButton";
import { ProjectSelectorButton } from "./ProjectSelectorButton";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  container: {},
  banner: {
    backgroundColor: colors.warning[50],
    paddingVertical: spacings[12],
    paddingHorizontal: spacings[72],
  },
  bannerClose: {
    position: "absolute",
    top: "50%",
    right: spacings[12],
    transform: "translateY(-50%)",
  },
  base: {
    backgroundColor: backgroundColor.accented,
    alignItems: "stretch",
    height: 64,
    flexDirection: "row",
    justifyContent: "center",
    boxShadow: "0 1px rgba(0, 0, 0, 0.06)",
  },
  content: {
    width: "100%",
    maxWidth: 2560,
    paddingHorizontal: 20,
  },
  logo: {
    height: "auto",
    width: 70,
  },
  headerLink: {
    ...texts.medium,
    color: colors.partner.primary,
    display: "flex",
    alignItems: "center",
  },
  linkSeparator: {
    marginVertical: "auto",
    height: "55%",
  },
});

const HeaderLink = ({
  desktop,
  href,
  icon,
  text,
}: {
  desktop: boolean;
  href?: string;
  icon: IconName;
  text: string;
}) => (
  <LakeText
    style={styles.headerLink}
    numberOfLines={1}
    {...(isNotNullishOrEmpty(href) && {
      href,
      hrefAttrs: { rel: "noopener noreferrer", target: "blank" },
      role: "link",
    })}
  >
    {desktop ? (
      <>
        <Icon name={icon} color={colors.partner.primary} size={20} />
        <Space width={8} />
        <Text numberOfLines={1}>{text}</Text>
      </>
    ) : (
      <LakeTooltip placement="center" content={text}>
        <Icon name={icon} color={colors.partner.primary} size={20} />
      </LakeTooltip>
    )}
  </LakeText>
);

const STORAGE_KEY = "lastSeenBanner";

const lastCloseBanner = atom(
  Option.fromNullable(getLocalStorageItem(STORAGE_KEY)).map(value => new Date(value)),
);

lastCloseBanner.subscribe(value => {
  if (value.isSome()) {
    setLocalStorageItem(STORAGE_KEY, value.get().toISOString());
  } else {
    removeLocalStorageItem(STORAGE_KEY);
  }
});

const ONE_DAY = 1000 * 60 * 60 * 24;
const YESTERDAY = Date.now() - ONE_DAY;

type Props = {
  user: LoggedUserInfoFragment;
  hasProjectFullLiveAccess: boolean;
};

export const Header = ({ hasProjectFullLiveAccess, user }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const { search } = useLocation();

  const shouldShowBanner = useAtomWithSelector(lastCloseBanner, date =>
    date.mapOr(true, date => date.getTime() <= YESTERDAY),
  );

  const banner = useTgglFlag("dashboardBanner")
    .flatMap(Option.fromNullable)
    .flatMap(value => (shouldShowBanner ? Option.Some(value) : Option.None()));

  const { write: canActivateProject } = usePermissions("live").projectStatusManagement;

  const showActivateButton = !hasProjectFullLiveAccess && canActivateProject;

  const onPressSupport = useCallback(() => {
    const redirectUrl = encodeURIComponent("https://swan3071.zendesk.com");
    window.open(`/zendesk/sso/${projectId}?redirect_url=${redirectUrl}`);
  }, [projectId]);

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  const client = match({ projectEnv, shouldUseProjectMemberToken })
    .with({ projectEnv: "live", shouldUseProjectMemberToken: true }, () =>
      livePartnerByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "live" }, () => livePartnerByProjectIdClient(projectId))
    .with({ projectEnv: "sandbox", shouldUseProjectMemberToken: true }, () =>
      sandboxPartnerByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "sandbox" }, () => sandboxPartnerByProjectIdClient(projectId))
    .exhaustive();

  return (
    <ResponsiveContainer breakpoint={showActivateButton ? 1600 : 1430} style={styles.container}>
      {({ large }) => (
        <>
          {banner
            .map(banner => (
              <View role="alert" style={styles.banner}>
                <LakeButton
                  style={styles.bannerClose}
                  icon="lake-close"
                  ariaLabel={t("common.close")}
                  mode="tertiary"
                  color="warning"
                  size="small"
                  onPress={() => lastCloseBanner.set(Option.Some(new Date()))}
                />

                <LakeText color={colors.warning[700]} variant="smallRegular" align="center">
                  <LakeText variant="smallSemibold" color={colors.warning[700]}>
                    {banner.title}:
                  </LakeText>{" "}
                  {banner.text}
                </LakeText>
              </View>
            ))
            .toNull()}

          <View role="banner" style={styles.base}>
            <Box direction="row" alignItems="center" style={styles.content}>
              <Link to={Router.BaseRoot()}>
                <SwanLogo style={styles.logo} />
              </Link>

              <Separator horizontal={true} space={24} style={styles.linkSeparator} />
              <ProjectSelectorButton user={user} />

              {showActivateButton && (
                <>
                  <Space width={24} />

                  <TrackPressable action="Activate">
                    <LakeButton
                      icon="flash-filled"
                      color="partner"
                      size="small"
                      onPress={() => {
                        pushUnsafe(`${encodeSearch({ ...search, activate: "true" })}`);
                      }}
                    >
                      {t("header.activate")}
                    </LakeButton>
                  </TrackPressable>
                </>
              )}

              <Space width={24} />

              <ClientContext.Provider value={client}>
                <GlobalSearch complete={large} />
              </ClientContext.Provider>

              <Fill minWidth={24} />

              <HeaderLink
                desktop={large}
                href="https://docs.swan.io"
                icon="document-regular"
                text={t("header.documentation")}
              />

              <Space width={20} />

              <HeaderLink
                desktop={large}
                href={env.EXPLORER_URL}
                icon="code-regular"
                text={t("header.apiExplorer")}
              />

              <Space width={20} />

              <TrackPressable action="Ask for support">
                <Pressable onPress={onPressSupport}>
                  <HeaderLink desktop={large} icon="chat-help-regular" text={t("header.support")} />
                </Pressable>
              </TrackPressable>

              <Separator horizontal={true} space={24} style={styles.linkSeparator} />
              <ProfileButton currentUser={user} />
            </Box>
          </View>
        </>
      )}
    </ResponsiveContainer>
  );
};
