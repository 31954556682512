import { useDeferredQuery } from "@swan-io/graphql-client";
import { Avatar } from "@swan-io/lake/src/components/Avatar";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FlatList } from "@swan-io/lake/src/components/FlatList";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { PressableText } from "@swan-io/lake/src/components/Pressable";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import {
  backgroundColor,
  colors,
  radii,
  spacings,
  texts,
} from "@swan-io/lake/src/constants/design";
import { useDebounce } from "@swan-io/lake/src/hooks/useDebounce";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { getFocusableElements } from "@swan-io/lake/src/utils/a11y";
import { safeSplitAround } from "@swan-io/lake/src/utils/string";
import { useCallback, useEffect, useId, useMemo, useRef, useState } from "react";
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputKeyPressEventData,
  View,
} from "react-native";
import { P, match } from "ts-pattern";
import { Except } from "type-fest";
import { GetUsersDocument } from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { sendMatomoEvent } from "../utils/matomo";
import { Router, topLevelRoutes } from "../utils/routes";

const MINIMUM_SEARCH_LENGTH = 2;

type Routes = (typeof topLevelRoutes)[number];

const styles = StyleSheet.create({
  container: {
    borderRadius: radii[8],
    borderWidth: 1,
    borderColor: colors.current[500],
    overflow: "hidden",
  },
  iconContainer: {
    width: 40,
    height: 40,
    backgroundColor: colors.current[500],
  },
  input: {
    width: 230,
    borderRadius: 0,
    border: "none",
  },
  smallInput: {
    ...texts.regular,
    paddingHorizontal: spacings[16],
    paddingLeft: spacings[48],
    outlineStyle: "none",
    height: 40,
    borderColor: colors.gray[100],
    borderTopRightRadius: radii[6],
    borderTopLeftRadius: radii[6],
    placeholderTextColor: colors.gray[400],
    borderBottomWidth: 1,
    backgroundColor: backgroundColor.accented,
    color: colors.gray[900],
    width: "100%",
    flexShrink: 1,
  },
  icon: {
    position: "absolute",
    left: spacings[16],
    top: "50%",
    transform: "translateY(-50%)",
  },
  contents: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "stretch",
  },
  results: {
    minWidth: 550,
    maxHeight: 450,
  },
  result: {
    width: "100%",
    paddingHorizontal: 24,
    paddingVertical: 14,
  },
  chevron: {
    paddingHorizontal: 4,
  },
  highlight: {
    backgroundColor: colors.sunglow[100],
    borderRadius: radii[4],
  },
  emptyList: {
    paddingHorizontal: 12,
    height: 136,
  },
  searchTitle: {
    color: colors.gray[900],
  },
  loadingContainer: {
    padding: 16,
  },
});

type Event = NativeSyntheticEvent<TextInputKeyPressEventData>;

type FeatureData = {
  sections: string[];
  description: string;
  link: Routes;
};

type UserData = {
  firstName: string;
  preferredLastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  id: string;
};

type Data = FeatureData | UserData | { type: "all_users" } | { type: "load_users" };

export const GlobalSearch = ({ complete }: { complete: boolean }) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canSearchUsers = usePermissions(projectEnv).dataUser.read;

  const inputRef = useRef<TextInput>(null);
  const smallInputRef = useRef<TextInput>(null);
  const listContainerRef = useRef<View>(null);

  const blurTimeoutId = useRef<number | undefined>(undefined);
  const [isFocused, { open, close }] = useDisclosure(false);

  const [search, setSearch] = useState("");
  const cleanSearch = search.trim().toLowerCase();

  const isAnySearchDisabled = cleanSearch.length < MINIMUM_SEARCH_LENGTH;
  const isUserSearchDisabled = isAnySearchDisabled || !canSearchUsers;

  const [data, { query }] = useDeferredQuery(GetUsersDocument);

  useEffect(() => {
    if (!isUserSearchDisabled) {
      const request = query({ first: 5, search });
      return () => request.cancel();
    }
  }, [isUserSearchDisabled, search, query]);

  const users = data
    .toOption()
    .flatMap(data => data.toOption())
    .map(data =>
      data.users.edges.map(({ node }) => ({
        id: node.id,
        firstName: node.firstName ?? "",
        preferredLastName: node.preferredLastName ?? "",
        fullName: node.fullName ?? "",
        phoneNumber: node.mobilePhoneNumber ?? "",
        email: node.accountMemberships.edges[0]?.node.email ?? "",
      })),
    );

  const loadUsersItem = data.isLoading() ? [{ type: "load_users" as const }] : [];

  const allUsersItem =
    !data.isLoading() && users.getOr([]).length > 0 ? [{ type: "all_users" as const }] : [];

  const sendSearchEvent = useDebounce<void>(() => {
    sendMatomoEvent({ type: "Action", category: "Search", name: "Global Search" });
  }, 500);

  useEffect(() => {
    if (search.length > MINIMUM_SEARCH_LENGTH) {
      sendSearchEvent();
    }
  }, [sendSearchEvent, search]);

  const suggestionsId = useId();

  const handleFocus = useCallback(() => {
    window.clearTimeout(blurTimeoutId.current);
    blurTimeoutId.current = window.setTimeout(() => {
      open();
    }, 100);
  }, [open]);

  const handleBlur = useCallback(() => {
    window.clearTimeout(blurTimeoutId.current);
    blurTimeoutId.current = window.setTimeout(() => {
      close();
    }, 100);
  }, [close]);

  const handleKeyPress = useCallback(
    (event: Event) => {
      if (event.nativeEvent.key === "ArrowDown") {
        const listElement = listContainerRef.current;
        if (listElement != null) {
          const element = listElement as unknown as Element;
          const focusableElements = getFocusableElements(element, false);
          // Search input is the first element on small screen.
          complete ? focusableElements[0]?.focus() : focusableElements[1]?.focus();
          event.preventDefault();
        }
      }
    },
    [complete],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies(complete):
  const handleListItemKeyPress = useCallback(
    (event: Event) => {
      if (event.nativeEvent.key === "Tab") {
        const focusableElements = getFocusableElements(undefined, true);
        const ref = inputRef.current;
        const index = focusableElements.indexOf(ref as unknown as HTMLElement);
        focusableElements[index + 1]?.focus();
        event.preventDefault();
      }

      if (event.nativeEvent.key === "ArrowDown" || event.nativeEvent.key === "ArrowUp") {
        const listElement = listContainerRef.current;
        if (listElement != null) {
          const element = listElement as unknown as Element;
          const target = event.currentTarget as unknown as HTMLElement;
          const focusableElements = getFocusableElements(element, false);
          const index = focusableElements.indexOf(target);
          const direction = event.nativeEvent.key === "ArrowDown" ? 1 : -1;
          if (index === -1) {
            return;
          }
          const nextIndex = index + direction;
          event.preventDefault();

          if (nextIndex === -1) {
            inputRef.current?.focus();
          } else {
            focusableElements[nextIndex === focusableElements.length ? 0 : nextIndex]?.focus();
          }
        }
      }
    },
    [complete],
  );

  const availableFeatures: FeatureData[] = useMemo(
    () => [
      {
        sections: ["data", "onboardings"],
        description: t("onboarding.subtitle"),
        link: "OnboardingRoot",
      },
      {
        sections: ["data", "account holders"],
        description: t("accounts.subtitle"),
        link: "HoldersRoot",
      },
      {
        sections: ["data", "accounts"],
        description: t("accounts.subtitle"),
        link: "AccountsRoot",
      },
      {
        sections: ["data", "account memberships"],
        description: t("accountMembership.subtitle"),
        link: "AccountMembershipsRoot",
      },
      {
        sections: ["data", "cards"],
        description: t("cards.subtitle"),
        link: "CardRoot",
      },
      {
        sections: ["developers", "API"],
        description: t("api.subtitle"),
        link: "DevelopersApi",
      },
      {
        sections: ["developers", "event simulator"],
        description: t("simulator.subtitle"),
        link: "DevelopersSimulatorRoot",
      },
      {
        sections: ["developers", "sandbox users"],
        description: t("sandboxUsers.subtitle"),
        link: "DevelopersUsersRoot",
      },
      {
        sections: ["developers", "webhooks"],
        description: t("webhooks.subtitle"),
        link: "DevelopersWebhooksRoot",
      },
      {
        sections: ["developers", "payment control"],
        description: t("paymentControl.subtitle"),
        link: "DevelopersPaymentControl",
      },
      {
        sections: ["developers", "consent notification"],
        description: t("consentNotification.description"),
        link: "DevelopersConsentNotification",
      },
      {
        sections: ["developers", "server consent"],
        description: t("serverConsent.description"),
        link: "DevelopersServerConsentDraft",
      },
      {
        sections: ["settings", "branding"],
        description: t("projectSettings.branding.subtitle"),
        link: "SettingsBrandingRoot",
      },
      {
        sections: ["settings", "cards"],
        description: t("projectSettings.cards.subtitle"),
        link: "SettingsCardProducts",
      },
      {
        sections: ["settings", "web banking"],
        description: t("projectSettings.webBanking.subtitle"),
        link: "SettingsWebBanking",
      },
      {
        sections: ["admin", "team"],
        description: t("members.subtitle"),
        link: "ProjectAdminTeam",
      },
    ],
    [],
  );

  useEffect(() => {
    if (isFocused) {
      smallInputRef.current?.focus();
    }
  }, [isFocused]);

  // biome-ignore lint/correctness/useExhaustiveDependencies(complete):
  useEffect(() => handleBlur(), [complete, handleBlur]);

  // biome-ignore lint/correctness/useExhaustiveDependencies(projectEnv):
  useEffect(() => {
    setSearch("");
    handleBlur();
  }, [projectEnv, handleBlur]);

  const featureResults = useMemo(() => {
    if (cleanSearch === "") {
      return [];
    }

    return availableFeatures
      .filter(({ sections, description }) =>
        `${sections.join("").toLowerCase()} ${description.toLowerCase()}`.includes(cleanSearch),
      )
      .sort(a => {
        const matchInSections = a.sections.join("").toLowerCase().includes(cleanSearch);
        return matchInSections ? -1 : 0;
      });
  }, [cleanSearch, availableFeatures]);

  return (
    <>
      {complete ? (
        <Box direction="row" alignItems="center" style={styles.container}>
          <Box alignItems="center" justifyContent="center" style={styles.iconContainer}>
            <Icon name="search-filled" size={20} color={colors.current.contrast} />
          </Box>

          <LakeTextInput
            style={styles.input}
            hideErrors={true}
            ref={inputRef}
            ariaExpanded={isFocused}
            ariaControls={isFocused ? suggestionsId : ""}
            value={search}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            color="current"
            onChangeText={setSearch}
            placeholder={t("search.placeholder")}
          />
        </Box>
      ) : (
        <LakeButton
          size="small"
          ref={inputRef}
          ariaExpanded={isFocused}
          ariaControls={isFocused ? suggestionsId : ""}
          ariaLabel={t("common.search")}
          icon="search-filled"
          mode="tertiary"
          onPress={open}
        />
      )}

      <Popover
        role="listbox"
        id={suggestionsId}
        matchReferenceWidth={false}
        onDismiss={handleBlur}
        autoFocus={false}
        underlay={false}
        referenceRef={inputRef}
        returnFocus={false}
        visible={complete ? isFocused && !isAnySearchDisabled : isFocused}
      >
        <View ref={listContainerRef} style={styles.results}>
          <FlatList<Data>
            role="list"
            data={[
              ...(!isAnySearchDisabled ? featureResults : []),
              ...(!isUserSearchDisabled
                ? [...allUsersItem, ...loadUsersItem, ...users.getOr([])]
                : []),
            ]}
            keyExtractor={item =>
              match(item)
                .with({ sections: P.array(P.string) }, ({ sections }) => sections.join("-"))
                .with({ type: "all_users" }, () => "all_users")
                .with({ type: "load_users" }, () => "load_users")
                .with({ id: P.string }, ({ id }) => id)
                .exhaustive()
            }
            ListEmptyComponent={isAnySearchDisabled ? <ListNotFound /> : <ListEmpty />}
            ListHeaderComponent={
              complete ? undefined : (
                <View style={styles.contents}>
                  <TextInput
                    style={styles.smallInput}
                    ref={smallInputRef}
                    value={search}
                    onBlur={handleBlur}
                    onKeyPress={handleKeyPress}
                    onChangeText={setSearch}
                    placeholder={t("search.placeholder")}
                  />

                  <Icon
                    name="search-filled"
                    size={20}
                    color={colors.current.primary}
                    style={styles.icon}
                  />
                </View>
              )
            }
            ItemSeparatorComponent={<Separator />}
            renderItem={({ item }) => {
              return match(item)
                .with({ sections: P.array(P.string) }, ({ description, link, sections }) => (
                  <FeatureResultItem
                    cleanSearch={cleanSearch}
                    description={description}
                    sections={sections}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onKeyDown={handleListItemKeyPress}
                    onPress={() => {
                      Router.push(link, { projectId, projectEnv });
                      setSearch("");
                      handleBlur();
                    }}
                  />
                ))
                .with({ type: "all_users" }, () => (
                  <AllUsersItem
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onKeyDown={handleListItemKeyPress}
                    onPress={() => {
                      Router.push("UserList", { projectId, projectEnv });
                      setSearch("");
                      handleBlur();
                    }}
                  />
                ))
                .with({ type: "load_users" }, () => <LoadingView style={styles.loadingContainer} />)
                .with({ id: P.string }, user => (
                  <UserResultItem
                    user={user}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onKeyDown={handleListItemKeyPress}
                    onPress={() => {
                      Router.push("UserDetailRoot", { projectId, projectEnv, userId: user.id });
                      setSearch("");
                      handleBlur();
                    }}
                  />
                ))
                .exhaustive();
            }}
          />
        </View>
      </Popover>
    </>
  );
};

const HighlightText = ({
  text,
  search,
  color,
}: {
  text: string;
  search: string;
  color?: string;
}) => {
  const tokens = search === "" ? [text] : safeSplitAround(text.toLowerCase(), search);

  return (
    <LakeText>
      {tokens.map((token, index) => {
        const colored = token.toLowerCase() === search;

        return (
          <LakeText key={index} color={color} style={colored && styles.highlight}>
            {index === 0 ? `${token.charAt(0).toUpperCase() + token.slice(1)}` : token}
          </LakeText>
        );
      })}
    </LakeText>
  );
};

type FeatureResultItemProps = Except<FeatureData, "link"> & {
  cleanSearch: string;
  onFocus: () => void;
  onBlur: () => void;
  onKeyDown: (event: Event) => void;
  onPress: () => void;
};

const FeatureResultItem = ({
  cleanSearch,
  description,
  sections,
  onFocus,
  onBlur,
  onKeyDown,
  onPress,
}: FeatureResultItemProps) => (
  <PressableText
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyDown={onKeyDown}
    role="listitem"
    onPress={onPress}
  >
    {({ hovered, focused }) => (
      <View style={[styles.result, (hovered || focused) && { backgroundColor: colors.gray[50] }]}>
        <Box direction="row" alignItems="center">
          {sections.map((cat, i) => (
            <Box direction="row" alignItems="center" key={i}>
              {i !== 0 && (
                <Icon
                  name="chevron-right-filled"
                  style={styles.chevron}
                  color={colors.current[500]}
                  size={16}
                />
              )}

              <HighlightText color={colors.current[500]} text={cat} search={cleanSearch} />
            </Box>
          ))}
        </Box>

        <Space height={8} />
        <HighlightText text={description} search={cleanSearch} />
      </View>
    )}
  </PressableText>
);

type AllUsersItemProps = {
  onFocus: () => void;
  onBlur: () => void;
  onKeyDown: (event: Event) => void;
  onPress: () => void;
};

const AllUsersItem = ({ onFocus, onBlur, onKeyDown, onPress }: AllUsersItemProps) => (
  <PressableText
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyDown={onKeyDown}
    role="listitem"
    onPress={onPress}
  >
    {({ hovered, focused }) => (
      <View style={[styles.result, (hovered || focused) && { backgroundColor: colors.gray[50] }]}>
        <Box direction="row" alignItems="center">
          <LakeText variant="medium" color={colors.gray[600]}>
            Users
          </LakeText>

          <Fill />

          <LakeText variant="smallMedium" color={colors.gray[600]}>
            See all
          </LakeText>
        </Box>
      </View>
    )}
  </PressableText>
);

type UserResultItemProps = {
  user: UserData;
  onFocus: () => void;
  onBlur: () => void;
  onKeyDown: (event: Event) => void;
  onPress: () => void;
};

const UserResultItem = ({ user, onFocus, onBlur, onKeyDown, onPress }: UserResultItemProps) => (
  <PressableText
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyDown={onKeyDown}
    role="listitem"
    onPress={onPress}
  >
    {({ hovered, focused }) => (
      <View style={[styles.result, (hovered || focused) && { backgroundColor: colors.gray[50] }]}>
        <Box direction="row" alignItems="center">
          <Avatar user={user} size={32} />
          <Space width={12} />

          <View>
            <Box direction="row">
              <LakeText variant="medium" color={colors.current[500]}>
                {user.fullName}
              </LakeText>

              <Space width={24} />

              <LakeText variant="smallRegular" color={colors.gray[700]}>
                {user.email}
              </LakeText>

              <Space width={24} />

              <LakeText variant="smallRegular" color={colors.gray[700]}>
                {user.phoneNumber}
              </LakeText>
            </Box>

            <LakeText variant="smallRegular" color={colors.gray[300]}>
              {user.id}
            </LakeText>
          </View>
        </Box>
      </View>
    )}
  </PressableText>
);

const ListNotFound = () => (
  <Box justifyContent="center" alignItems="center" style={styles.emptyList}>
    <Icon name="clipboard-search-regular" size={34} color={colors.current[500]} />
    <Space height={8} />

    <LakeText variant="semibold" style={styles.searchTitle}>
      {t("search.notFound.title")}
    </LakeText>

    <LakeText variant="smallRegular">{t("search.notFound.description")}</LakeText>
  </Box>
);

const ListEmpty = () => (
  <Box justifyContent="center" alignItems="center" style={styles.emptyList}>
    <Icon name="search-filled" size={34} color={colors.current[500]} />
    <Space height={8} />

    <LakeText variant="semibold" style={styles.searchTitle}>
      {t("search.empty.title")}
    </LakeText>

    <LakeText variant="smallRegular">{t("search.empty.description")}</LakeText>
  </Box>
);
