import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { TilePlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { CountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { GetProjectInfoDocument } from "../graphql/exposed-internal";
import { formatNestedMessage, t } from "../utils/i18n";
import { ErrorView } from "./ErrorView";

const styles = StyleSheet.create({
  tileContents: {
    flexShrink: 1,
  },
  link: {
    display: "flex",
    flexDirection: "column",
  },
});
type ContractTileProps = {
  icon: IconName;
  title: string;
  description: string;
  href: string;
};

const ContractTile = ({ icon, title, description, href }: ContractTileProps) => {
  return (
    <Link to={href} style={styles.link} target="blank">
      {({ hovered }) => (
        <Tile hovered={hovered}>
          <Box direction="row" alignItems="center">
            <Icon name={icon} size={42} color={colors.current.primary} />
            <Space width={24} />

            <Box style={styles.tileContents}>
              <LakeHeading level={2} variant="h3">
                {title}
              </LakeHeading>

              <LakeText variant="regular" color={colors.gray[500]}>
                {description}
              </LakeText>
            </Box>

            <Fill />
            <Space width={24} />
            <Icon name="open-filled" size={24} color={colors.gray[500]} />
          </Box>
        </Tile>
      )}
    </Link>
  );
};

type Props = {
  fullName?: string;
  projectName?: string;
  tcuUrl?: string;
  accountCountry?: CountryCCA3;
};

const SWAN_HELLO_EMAIL = "hello@swan.io";
const TERMS_OF_USE_URL = "https://www.swan.io/terms-of-use";

export const ProjectActivationReviewAndSignContracts = ({
  fullName,
  projectName,
  tcuUrl,
}: // accountCountry,
Props) => {
  const [data] = useQuery(GetProjectInfoDocument, { language: "en" });

  useCrumb(
    useMemo(() => {
      return {
        label: t("projectActivation.contracts"),
        link: "?activate=true&step=ReviewAndSignContracts",
      };
    }, []),
  );

  return (
    <>
      <Breadcrumbs />
      <Space height={40} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TilePlaceholder />)
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
          const tcuDocumentUri = data?.projectInfo.tcuDocumentUri;

          return (
            <>
              <Tile
                title={
                  fullName != null
                    ? t("projectActivationSignContracts.hi", { fullName })
                    : t("projectActivationSignContracts.hiNoName")
                }
              >
                <LakeText>
                  {formatNestedMessage("projectActivationSignContracts.description", {
                    email: (
                      <LakeText color={colors.current.primary} href={`mailto:${SWAN_HELLO_EMAIL}`}>
                        {SWAN_HELLO_EMAIL}
                      </LakeText>
                    ),
                  })}
                </LakeText>
              </Tile>

              <Space height={24} />

              <LakeHeading level={2} variant="h2">
                {t("projectActivationSignContracts.specificContractTitle")}
              </LakeHeading>

              <Space height={24} />

              <ContractTile
                icon="lake-document-pdf"
                title={t("projectActivationSignContracts.standardPartnershipContract")}
                description={t(
                  "projectActivationSignContracts.standardPartnershipContractDescription",
                )}
                href="/standard-partnership-contract.pdf"
              />

              <Space height={24} />

              <LakeHeading level={2} variant="h2">
                {t("projectActivationSignContracts.genericContractsitle")}
              </LakeHeading>

              {isNotNullish(tcuUrl) ? (
                <>
                  <Space height={24} />

                  <ContractTile
                    icon="lake-document-pdf"
                    title={t("projectActivationSignContracts.swanTCU")}
                    description={t("projectActivationSignContracts.swanTCUDescription")}
                    href={tcuUrl}
                  />
                </>
              ) : null}

              {match(tcuDocumentUri)
                .with(P.string, tcuDocumentUri => (
                  <>
                    <Space height={24} />

                    <ContractTile
                      icon="lake-document-pdf"
                      title={
                        projectName != null
                          ? t("projectActivationSignContracts.partnershipConditions", {
                              projectName,
                            })
                          : t("projectActivationSignContracts.partnershipConditionsNoName")
                      }
                      description={t(
                        "projectActivationSignContracts.partnershipConditionsDescription",
                      )}
                      href={tcuDocumentUri}
                    />
                  </>
                ))
                .otherwise(() => null)}

              <Space height={24} />

              <ContractTile
                icon="lake-world-map"
                title={t("projectActivationSignContracts.swanApplication")}
                description={t("projectActivationSignContracts.swanApplicationDescription")}
                href={TERMS_OF_USE_URL}
              />
            </>
          );
        })
        .exhaustive()}
    </>
  );
};
