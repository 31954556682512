import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { useForm } from "@swan-io/use-form";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { FnciResult, GenerateTestCheckDocument } from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

type FormApi = {
  fnciResult: FnciResult;
};

const fnciResults = deriveUnion<FnciResult>({
  PositiveGreen: true,
  IrregularWhite: true,
  IrregularOrange: true,
  IrregularRed: true,
}).array.map(value => ({ name: value, value }));

export const MerchantPaymentsChecksGenerateTest = () => {
  const [mutate, simulation] = useMutation(GenerateTestCheckDocument);

  const result = simulation.mapOkToResult(({ response }) =>
    match(response)
      .with({ __typename: "GenerateCheckSuccessPayload" }, ({ cmc7, rlmcKey }) =>
        Result.Ok([
          { key: "cmc7", value: cmc7 },
          { key: "rlmcKey", value: rlmcKey },
        ]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, formStatus, submitForm } = useForm<FormApi>({
    fnciResult: {
      initialValue: "PositiveGreen",
    },
  });

  const onPressSubmit = () => {
    submitForm({
      onSuccess: values => {
        return Option.allFromDict(values)
          .map(({ fnciResult }) => {
            mutate({
              input: {
                fnciResult,
              },
            });
          })
          .toUndefined();
      },
    });
  };

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.merchantPayments.check.generateTest.description")}{" "}
            <DocumentationLink to="merchant">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="fnciResult">
            {({ value, error, onChange }) => (
              <LakeLabel
                label={`${t("simulatorForm.fnciResult")}`}
                render={id => (
                  <LakeSelect
                    id={id}
                    value={value}
                    items={fnciResults}
                    onValueChange={onChange}
                    error={error}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit merchant generate test check">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onPressSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            { key: "cmc7", label: t("simulatorForm.cmc7"), placeholder: "-" },
            { key: "rlmcKey", label: t("simulatorForm.rlmcKey"), placeholder: "-" },
          ]}
          results={result}
        />
      </Box>
    </Form>
  );
};
