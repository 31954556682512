import { AsyncData, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { InformationTooltip, LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Switch } from "@swan-io/lake/src/components/Switch";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors } from "@swan-io/lake/src/constants/design";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { CopyTextButton } from "../components/CopyTextButton";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  GetWebBankingPageDocument,
  GetWebBankingPageQuery,
  UpdateWebBankingSettingsDocument,
} from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import { t } from "../utils/i18n";
import { useTgglFlag } from "../utils/tggl";

const styles = StyleSheet.create({
  settingsLine: {
    height: 48,
  },
});

type WebBankingSettingsState = {
  canViewAccountDetails: boolean;
  canViewAccountStatement: boolean;
  canManageVirtualIbans: boolean;
  canInitiatePaymentsToNewBeneficiaries: boolean;
  canViewPaymentList: boolean;
  canOrderVirtualCards: boolean;
  canOrderPhysicalCards: boolean;
  canViewMembers: boolean;
  canAddNewMembers: boolean;
  canCreateMerchantProfile: boolean;
  canRequestOnlineCardsPaymentMethod: boolean;
  canRequestSepaDirectDebitCorePaymentMethod: boolean;
  canRequestSepaDirectDebitB2BPaymentMethod: boolean;
  canRequestInternalDirectDebitCorePaymentMethod: boolean;
  canRequestInternalDirectDebitB2BPaymentMethod: boolean;
  canRequestChecksPaymentMethod: boolean;
  canInitiateCheckMerchantPayments: boolean;
  canCreateMerchantPaymentLinks: boolean;
};

type WebBankingSettingsLineProps = {
  label: string;
  settings: WebBankingSettingsState;
  settingsKey: keyof WebBankingSettingsState;
  setSettings: (setter: (value: WebBankingSettingsState) => WebBankingSettingsState) => void;
  disabled: boolean;
};

const WebBankingSettingsLine = ({
  label,
  settings,
  settingsKey,
  setSettings,
  disabled,
}: WebBankingSettingsLineProps) => {
  const handleChange = (value: boolean) => {
    setSettings(s => ({
      ...s,
      [settingsKey]: value,
      ...(settingsKey === "canOrderVirtualCards" && value === false
        ? { canOrderPhysicalCards: false }
        : null),
      ...(settingsKey === "canViewMembers" && value === false ? { canAddNewMembers: false } : null),
    }));
  };
  const value = settings[settingsKey];

  return (
    <Box direction="row" alignItems="center" style={styles.settingsLine}>
      <LakeTooltip placement="right" content={t("common.action.denied")} disabled={!disabled}>
        <Switch key={settingsKey} value={value} disabled={disabled} onValueChange={handleChange} />
      </LakeTooltip>

      <Space width={12} />
      <LakeText color={colors.gray[800]}>{label}</LakeText>
    </Box>
  );
};

const WebBanking = ({ data, reload }: { data: GetWebBankingPageQuery; reload: () => void }) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canEditWebBankingSettings = usePermissions(projectEnv).settingsWebBanking.write;
  const readonly = !canEditWebBankingSettings;

  const isMerchantWebBankingFlagActive = useTgglFlag("merchantWebBanking").getOr(false);

  const [updateSettings, update] = useMutation(UpdateWebBankingSettingsDocument);

  const isLive = projectEnv === "live";

  const project = data.project;

  const webbankingSettings = isLive
    ? project.liveWebBankingSettings
    : project.sandboxWebBankingSettings;
  const webBankingUrl = isLive
    ? `${env.BANKING_LIVE_URL}/projects/${projectId}`
    : `${env.BANKING_SANDBOX_URL}/projects/${projectId}`;

  const [settings, setSettings] = useState<WebBankingSettingsState>(() => ({
    canViewAccountDetails: webbankingSettings?.canViewAccountDetails ?? true,
    canViewAccountStatement: webbankingSettings?.canViewAccountStatement ?? true,
    canManageVirtualIbans: webbankingSettings?.canManageVirtualIbans ?? true,
    canInitiatePaymentsToNewBeneficiaries:
      webbankingSettings?.canInitiatePaymentsToNewBeneficiaries ?? true,
    canViewPaymentList: webbankingSettings?.canViewPaymentList ?? true,
    canOrderVirtualCards: webbankingSettings?.canOrderVirtualCards ?? true,
    canOrderPhysicalCards: webbankingSettings?.canOrderPhysicalCards ?? true,
    canViewMembers: webbankingSettings?.canViewMembers ?? true,
    canAddNewMembers: webbankingSettings?.canAddNewMembers ?? true,
    canViewAccountStatements: webbankingSettings?.canViewAccountStatement ?? true,
    canCreateMerchantProfile: webbankingSettings?.canCreateMerchantProfile ?? true,
    canRequestOnlineCardsPaymentMethod:
      webbankingSettings?.canRequestOnlineCardsPaymentMethod ?? true,
    canRequestSepaDirectDebitCorePaymentMethod:
      webbankingSettings?.canRequestSepaDirectDebitCorePaymentMethod ?? true,
    canRequestSepaDirectDebitB2BPaymentMethod:
      webbankingSettings?.canRequestSepaDirectDebitB2BPaymentMethod ?? true,
    canRequestInternalDirectDebitCorePaymentMethod:
      webbankingSettings?.canRequestInternalDirectDebitCorePaymentMethod ?? true,
    canRequestInternalDirectDebitB2BPaymentMethod:
      webbankingSettings?.canRequestInternalDirectDebitB2BPaymentMethod ?? true,
    canRequestChecksPaymentMethod: webbankingSettings?.canRequestChecksPaymentMethod ?? true,
    canInitiateCheckMerchantPayments: webbankingSettings?.canInitiateCheckMerchantPayments ?? true,
    canCreateMerchantPaymentLinks: webbankingSettings?.canCreateMerchantPaymentLinks ?? true,
  }));

  useEffect(() => {
    if (webbankingSettings) {
      setSettings({
        canViewAccountDetails: webbankingSettings.canViewAccountDetails ?? true,
        canViewAccountStatement: webbankingSettings.canViewAccountStatement ?? true,
        canManageVirtualIbans: webbankingSettings.canManageVirtualIbans ?? true,
        canInitiatePaymentsToNewBeneficiaries:
          webbankingSettings.canInitiatePaymentsToNewBeneficiaries ?? true,
        canViewPaymentList: webbankingSettings.canViewPaymentList ?? true,
        canOrderVirtualCards: webbankingSettings.canOrderVirtualCards ?? true,
        canOrderPhysicalCards: webbankingSettings.canOrderPhysicalCards ?? true,
        canViewMembers: webbankingSettings.canViewMembers ?? true,
        canAddNewMembers: webbankingSettings.canAddNewMembers ?? true,
        canCreateMerchantProfile: webbankingSettings?.canCreateMerchantProfile ?? true,
        canRequestOnlineCardsPaymentMethod:
          webbankingSettings?.canRequestOnlineCardsPaymentMethod ?? true,
        canRequestSepaDirectDebitCorePaymentMethod:
          webbankingSettings?.canRequestSepaDirectDebitCorePaymentMethod ?? true,
        canRequestSepaDirectDebitB2BPaymentMethod:
          webbankingSettings?.canRequestSepaDirectDebitB2BPaymentMethod ?? true,
        canRequestInternalDirectDebitCorePaymentMethod:
          webbankingSettings?.canRequestInternalDirectDebitCorePaymentMethod ?? true,
        canRequestInternalDirectDebitB2BPaymentMethod:
          webbankingSettings?.canRequestInternalDirectDebitB2BPaymentMethod ?? true,
        canRequestChecksPaymentMethod: webbankingSettings?.canRequestChecksPaymentMethod ?? true,
        canInitiateCheckMerchantPayments:
          webbankingSettings?.canInitiateCheckMerchantPayments ?? true,
        canCreateMerchantPaymentLinks: webbankingSettings?.canCreateMerchantPaymentLinks ?? true,
      });
    }
  }, [webbankingSettings]);

  const handleSaveSettings = (settings: WebBankingSettingsState) => {
    updateSettings({
      input: { projectId, env: capitalize(projectEnv), ...settings },
    })
      .tapOk(reload)
      .tapError(error =>
        showToast({ variant: "error", error, title: t("toast.error.webbankingSettings") }),
      );
  };

  const setSettingsAndSave = (
    getNextSettings: (settings: WebBankingSettingsState) => WebBankingSettingsState,
  ) => {
    const nextSettings = getNextSettings(settings);
    setSettings(nextSettings);
    handleSaveSettings(nextSettings);
  };

  return (
    <TileGrid>
      <Tile
        icon={<Icon size={24} name="apps-list-regular" color={colors.current.primary} />}
        title={t("projectSettings.webBanking.tab.history")}
        headerEnd={<InformationTooltip text={t("projectSettings.webBanking.history.text")} />}
      >
        <WebBankingSettingsLine
          label={t("projectSettings.webBanking.history.canViewAccountStatements")}
          settingsKey="canViewAccountStatement"
          settings={settings}
          setSettings={setSettingsAndSave}
          disabled={update.isLoading() || readonly}
        />
      </Tile>

      <Tile
        icon={<Icon size={24} name="building-bank-regular" color={colors.current.primary} />}
        title={t("projectSettings.webBanking.tab.accounts")}
        headerEnd={<InformationTooltip text={t("projectSettings.webBanking.accounts.text")} />}
      >
        <WebBankingSettingsLine
          label={t("projectSettings.webBanking.accounts.canViewAccountDetails")}
          settingsKey="canViewAccountDetails"
          settings={settings}
          setSettings={setSettingsAndSave}
          disabled={update.isLoading() || readonly}
        />

        <WebBankingSettingsLine
          label={t("projectSettings.webBanking.accounts.canManageIbans")}
          settingsKey="canManageVirtualIbans"
          settings={settings}
          setSettings={setSettingsAndSave}
          disabled={update.isLoading() || readonly}
        />
      </Tile>

      <Tile
        icon={<Icon size={24} name="arrow-swap-regular" color={colors.current.primary} />}
        title={t("projectSettings.webBanking.tab.payments")}
        headerEnd={<InformationTooltip text={t("projectSettings.webBanking.payments.text")} />}
      >
        <WebBankingSettingsLine
          label={t("projectSettings.webBanking.payments.canInitiateCredit")}
          settingsKey="canInitiatePaymentsToNewBeneficiaries"
          settings={settings}
          setSettings={setSettingsAndSave}
          disabled={update.isLoading() || readonly}
        />

        <WebBankingSettingsLine
          label={t("projectSettings.webBanking.payments.canViewPayments")}
          settingsKey="canViewPaymentList"
          settings={settings}
          setSettings={setSettingsAndSave}
          disabled={update.isLoading() || readonly}
        />
      </Tile>

      <Tile
        icon={<Icon size={24} name="payment-regular" color={colors.current.primary} />}
        title={t("projectSettings.webBanking.tab.cards")}
        headerEnd={<InformationTooltip text={t("projectSettings.webBanking.cards.text")} />}
      >
        <WebBankingSettingsLine
          label={t("projectSettings.webBanking.cards.canOrderVirtualCards")}
          settingsKey="canOrderVirtualCards"
          settings={settings}
          setSettings={setSettingsAndSave}
          disabled={update.isLoading() || readonly}
        />

        <WebBankingSettingsLine
          label={t("projectSettings.webBanking.cards.canOrderPhysicalCards")}
          settingsKey="canOrderPhysicalCards"
          settings={settings}
          setSettings={setSettingsAndSave}
          disabled={!settings.canOrderVirtualCards || update.isLoading() || readonly}
        />
      </Tile>

      <Tile
        icon={<Icon size={24} name="people-regular" color={colors.current.primary} />}
        title={t("projectSettings.webBanking.tab.members")}
        headerEnd={<InformationTooltip text={t("projectSettings.webBanking.members.text")} />}
      >
        <WebBankingSettingsLine
          label={t("projectSettings.webBanking.members.canManageMembers")}
          settingsKey="canViewMembers"
          settings={settings}
          setSettings={setSettingsAndSave}
          disabled={update.isLoading() || readonly}
        />

        <WebBankingSettingsLine
          label={t("projectSettings.webBanking.members.canAddMembers")}
          settingsKey="canAddNewMembers"
          settings={settings}
          setSettings={setSettingsAndSave}
          disabled={update.isLoading() || readonly}
        />
      </Tile>

      {isMerchantWebBankingFlagActive ? (
        <Tile
          icon={<Icon size={24} name="building-shop-regular" color={colors.current.primary} />}
          title={t("projectSettings.webBanking.tab.merchantProfile")}
          headerEnd={
            <InformationTooltip text={t("projectSettings.webBanking.merchantProfile.text")} />
          }
        >
          <WebBankingSettingsLine
            label={t("projectSettings.webBanking.merchantProfile.canCreateMerchantProfile")}
            settingsKey="canCreateMerchantProfile"
            settings={settings}
            setSettings={setSettingsAndSave}
            disabled={update.isLoading() || readonly}
          />

          <Separator space={24} />

          <LakeText variant="medium" color={colors.gray[900]}>
            {t("projectSettings.webBanking.merchantProfile.paymentMethods")}
          </LakeText>

          <Space height={12} />

          <LakeAlert
            variant="info"
            title={t("projectSettings.webBanking.merchantProfile.paymentMethods.help")}
          />

          <Space height={24} />

          <WebBankingSettingsLine
            label={t(
              "projectSettings.webBanking.merchantProfile.canRequestOnlineCardsPaymentMethod",
            )}
            settingsKey="canRequestOnlineCardsPaymentMethod"
            settings={settings}
            setSettings={setSettingsAndSave}
            disabled={update.isLoading() || readonly}
          />

          <WebBankingSettingsLine
            label={t(
              "projectSettings.webBanking.merchantProfile.canRequestSepaDirectDebitB2BPaymentMethod",
            )}
            settingsKey="canRequestSepaDirectDebitB2BPaymentMethod"
            settings={settings}
            setSettings={setSettingsAndSave}
            disabled={update.isLoading() || readonly}
          />

          <WebBankingSettingsLine
            label={t(
              "projectSettings.webBanking.merchantProfile.canRequestSepaDirectDebitCorePaymentMethod",
            )}
            settingsKey="canRequestSepaDirectDebitCorePaymentMethod"
            settings={settings}
            setSettings={setSettingsAndSave}
            disabled={update.isLoading() || readonly}
          />

          <WebBankingSettingsLine
            label={t("projectSettings.webBanking.merchantProfile.canRequestChecksPaymentMethod")}
            settingsKey="canRequestChecksPaymentMethod"
            settings={settings}
            setSettings={setSettingsAndSave}
            disabled={update.isLoading() || readonly}
          />

          <WebBankingSettingsLine
            label={t(
              "projectSettings.webBanking.merchantProfile.canRequestInternalDirectDebitB2BPaymentMethod",
            )}
            settingsKey="canRequestInternalDirectDebitB2BPaymentMethod"
            settings={settings}
            setSettings={setSettingsAndSave}
            disabled={update.isLoading() || readonly}
          />

          <WebBankingSettingsLine
            label={t(
              "projectSettings.webBanking.merchantProfile.canRequestInternalDirectDebitCorePaymentMethod",
            )}
            settingsKey="canRequestInternalDirectDebitCorePaymentMethod"
            settings={settings}
            setSettings={setSettingsAndSave}
            disabled={update.isLoading() || readonly}
          />
        </Tile>
      ) : null}

      <Tile
        icon={<Icon size={24} name="cursor-click-regular" color={colors.current.primary} />}
        title={t("projectSettings.webBanking.urls")}
      >
        <LakeLabel
          label={t("projectSettings.webBanking.urlLabel")}
          render={id => <LakeTextInput id={id} readOnly={true} value={webBankingUrl} />}
          actions={
            <>
              <CopyTextButton value={webBankingUrl} />
              <Space width={12} />

              <TrackPressable action="Open web banking">
                <LakeButton
                  mode="secondary"
                  size="small"
                  onPress={() => window.open(webBankingUrl)}
                  ariaLabel={t("projectSettings.webBanking.open")}
                  icon="open-filled"
                />
              </TrackPressable>
            </>
          }
        />
      </Tile>

      {isMerchantWebBankingFlagActive ? (
        <Tile
          icon={<Icon size={24} name="building-shop-regular" color={colors.current.primary} />}
          title={t("projectSettings.webBanking.tab.merchantPayment")}
          headerEnd={
            <InformationTooltip text={t("projectSettings.webBanking.merchantPayments.text")} />
          }
        >
          <WebBankingSettingsLine
            label={t("projectSettings.webBanking.merchantProfile.canInitiateCheckMerchantPayments")}
            settingsKey="canInitiateCheckMerchantPayments"
            settings={settings}
            setSettings={setSettingsAndSave}
            disabled={update.isLoading() || readonly}
          />

          <WebBankingSettingsLine
            label={t("projectSettings.webBanking.merchantProfile.canCreateMerchantPaymentLinks")}
            settingsKey="canCreateMerchantPaymentLinks"
            settings={settings}
            setSettings={setSettingsAndSave}
            disabled={update.isLoading() || readonly}
          />
        </Tile>
      ) : null}
    </TileGrid>
  );
};

export const WebBankingPage = () => {
  const { projectId } = useProjectInfo();

  const [data, { refresh }] = useQuery(GetWebBankingPageDocument, { projectId });

  return (
    <>
      <LakeAlert variant="warning" title={t("projectSettings.webBanking.disableWarning")} />
      <Space height={24} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <TileGridPlaceholder withTabs={false} />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <WebBanking
            data={data}
            reload={() => {
              refresh();
            }}
          />
        ))
        .exhaustive()}
    </>
  );
};
